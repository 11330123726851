import React from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "./MakingTransition.scss";

const MakingTransition = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Making the Transition",
    description:
      "Find out how The Salt Lake Tribune made the transition into a nonprofit and whether it works for your newsroom.",
    canonical: "https://playbook.sltrib.com/making-the-transition",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "nonprofit, nonprofit playbook, the salt lake tribune, transitioning to nonprofit",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="making-transition">
        <div className="making-transition-content">
          <h1>Making the Transition</h1>
          <p>
            Before receiving IRS approval as a nonprofit, The Tribune prepared a
            number of documents and plans. We worked with the law firm of Caplin
            and Drysdale to complete the applications to the IRS for The Salt
            Lake Tribune Inc. and a foundation created as a transitional
            nonprofit. We also contracted with a small local writing firm to
            develop a series of messages to communicate with various
            stakeholders about the decision.
          </p>
          <p>
            <a
              href="https://local.sltrib.com/nonprofit/application.pdf"
              target="_blank"
              rel="noreferrer"
            >
              The Salt Lake Tribune’s 1023 application
            </a>{" "}
            provides a comprehensive view of requirements. Topics in the
            application include:
          </p>
          <ul>
            <li>Past, current and planned activities of the organization.</li>
            <li>
              In-depth description of the organization’s charitable purpose.
            </li>
            <li>
              Disclosure of financial behavior, conflicts of interest, and
              organizational leadership.
            </li>
          </ul>
          <hr />
          <h2>The Tribune’s transition timeline</h2>
          <div class="timeline">
            <div class="outer">
              <div class="card">
                <div class="info">
                  <h3 class="title">May 2019</h3>
                  <p>Applied for 501(c)(3) public charity status.</p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">June 2019</h3>
                  <p>
                    Survey with current readers to explore their habits and
                    satisfaction with The Tribune.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">August 2019</h3>
                  <p>Founding Board starts meeting.</p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">October 2019</h3>
                  <p>Google News Initiative award received.</p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">November 2019</h3>
                  <p>
                    Salt Lake Tribune approved by IRS. Assessed revenue models
                    for 3 nonprofit scenarios. Focus groups with readers on
                    nonprofit status. All-staff meeting to share nonprofit
                    status. Press release announcing nonprofit status.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">December 2019</h3>
                  <p>
                    Identified and mapped all customer data sources. Fundraising
                    email campaign series. Began ongoing testing for optimized
                    messaging/calls to action for subscriptions and donations.
                    Created manifesto, case for support, champion interviews and
                    marketing materials.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">January 2020</h3>
                  <p>
                    Newsletter strategy overhaul begins. Full board established,
                    new members elected.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">February 2020</h3>
                  <p>
                    Attempted (unsuccessfully) to create a data warehouse using
                    Salesforce in partnership with News Revenue Hub to combine
                    print, digital, newsletter and other data sources.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">March 2020</h3>
                  <p>
                    Began SLTrib.com website redesign to be more user-friendly
                    and attractive to donors. Added journalism education
                    content. New reader tipline portal launched.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">May 2020</h3>
                  <p>
                    Began pop-up messaging and continued A/B testing of
                    subscribe / donate links on website. Developed new donation
                    accounting procedures. Facebook Accelerator: Designed a new
                    subscription squeeze page and a new donation page. Created a
                    new onboarding email series for newsletter and digital
                    subscribers. Gift acceptance guidelines developed, approved,
                    and added to website.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">June 2020</h3>
                  <p>Launched new Supporting Subscriber program.</p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">August 2020</h3>
                  <p>Launched bundled subscription with The New York Times.</p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">December 2020</h3>
                  <p>New SLTrib.com website launch. </p>
                  <p>
                    New SLTrib.com website launch. The Tribune ends its 68-year
                    joint operating agreement with the rival Deseret News.{" "}
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">January 2021</h3>
                  <p>
                    Tribune{" "}
                    <a
                      href="https://www.sltrib.com/news/2020/12/31/tribune-will-resume-print/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      moves print publication schedule{" "}
                    </a>
                    from 7 days a week to once a week, on Sundays.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">March 2021</h3>
                  <p>
                    The Salt Lake Tribune joins with news organizations across
                    the state to create the{" "}
                    <a
                      href="https://www.sltrib.com/news/2022/01/25/learn-more-about-utah/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Utah News Collaborative
                    </a>{" "}
                    — in which news outlets share stories daily from all corners
                    of the state, across print, digital, audio and video
                    platforms.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">November 2021</h3>
                  <p>
                    The Salt Lake Tribune and PBS’s “Frontline” collaborated on
                    the documentary “
                    <a
                      href="https://www.sltrib.com/news/2021/11/16/shots-fired-film-about/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Shots Fired
                    </a>
                    ,” investigating the use of deadly force by Utah law
                    enforcement, and creating a database of police shootings
                    because none existed with any municipal, state or federal
                    law enforcement agency. The documentary aired on PBS
                    stations nationwide, and remains available online.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">January 2022</h3>
                  <p>
                    Tribune adds a midweek print edition, arriving in
                    subscribers’ mailboxes on Wednesdays — bringing the paper’s
                    print schedule to twice a week.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">March 2022</h3>
                  <p>
                    The Tribune joins with 18 other media organizations in Utah
                    to form the{" "}
                    <a
                      href="https://greatsaltlakenews.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Great Salt Lake Collaborative
                    </a>
                    , to report on and draw attention to the potential
                    environmental disaster if the Great Salt Lake dries up. The
                    first episode of “
                    <a
                      href="https://www.sltrib.com/sentaway/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sent Away
                    </a>
                    ,” a podcast investigating Utah’s “troubled teen industry,”
                    debuts; the seven-episode podcast is a collaborative effort
                    among The Salt Lake Tribune, KUER and APM Reports.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h2>Messaging and communication </h2>
          <p>
            This should be the easy part for journalists. We’re in the
            communications business, and telling stories is what we do. But
            sharing how a 150-year-old organization is transforming is not easy.
          </p>
          <p>
            When The Tribune started preparing our application to the IRS, we
            developed a strategy for communicating the change in status. This
            process included designing messaging, testing that language and
            receiving community feedback when applicable, and selection of
            messaging owners and leaders can use to communicate with various
            stakeholders.
          </p>
          <h4>
            Examples of The Salt Lake Tribune’s messaging around the nonprofit
            change:
          </h4>
          <ul>
            <li style={{ textIndent: "25px" }}>Salt Lake Tribune Manifesto</li>
            <li style={{ textIndent: "25px" }}> Calls to action</li>
            <li style={{ textIndent: "25px" }}>Letter to readers</li>
            <li style={{ textIndent: "25px" }}>
              Nonprofit launch social media communication{" "}
            </li>
            <li style={{ textIndent: "25px" }}>
              Nonprofit launch email campaign
            </li>
          </ul>
          <p>
            To succeed as a nonprofit, everyone — readers and subscribers, the
            Board of Directors and the general public — must understand the news
            organization’s financial reality. If people don’t know what you’re
            up against, they may not feel motivated to support you.
          </p>
          <p>
            {" "}
            In the first couple of years, The Tribune found that telling people
            at the beginning wasn’t enough — and just saying the word
            “nonprofit” wasn’t enough.
          </p>
          <p>
            {" "}
            We would staff a booth at events around Salt Lake City, to talk to
            the community. While we were celebrated nationally, very few Utahns
            knew we were a nonprofit. They told us they thought we were still
            owned by Paul Huntsman, and would ask us, “Why are you asking us for
            support if you’re owned by a billionaire?” The answer — “we are
            owned by you” — is one we at The Tribune repeat constantly, so that
            it sinks in.
          </p>
          <p>
            {" "}
            When you become a nonprofit, make it clear in your messaging from
            the beginning that you, as a nonprofit, are not owned by some rich
            person or big corporation. You are “owned” by the community, and to
            the community you are accountable.
          </p>
          <p>
            Being honest and transparent with the public — which is already The
            Tribune’s mission, in its journalism — was important when talking
            about financial need early on, because it allowed The Tribune to
            build a relationship with the community, and set the stage for
            philanthropy.
          </p>
          <p>
            As we’ve gone further down the road as a nonprofit, the message
            isn’t just top-down — everyone has become a disciple for
            community-minded journalism. Being community-owned makes us work
            that much harder, every day, to figure out how to make The Tribune
            essential to the people of Utah.
          </p>
          <p>
            The editor leads our newsroom in getting that message out, with
            regular reminders that it’s the community support that has allowed
            The Tribune to grow our newsroom, add reporters statewide to cover
            critical issues, collaborate with others, and share our stories with
            other news organizations in Utah.
          </p>
          <p>
            The best messaging is genuine and purposeful. Saying “Support The
            Tribune” can be effective for some, but saying “Support this
            journalism about the Great Salt Lake” can, to the right donors, be
            more effective. Aligning your transition messaging with your
            marketing strategy is important.
          </p>
          <hr />
          <h2>Fundraising staff</h2>
          <p>
            One of the first new hires The Tribune made as it was moving to a
            nonprofit model was someone with the responsibility and focus to
            identify and cultivate donors. Many donors are subscribers, but the
            development relationship is different than that of a reader.
          </p>
          <p>
            This is important: Development, like journalism, is a profession —
            with its own processes, strategy and ethics. Journalists can make
            great development officers, but we strongly caution against just
            assigning a reporter to be a fundraiser.
          </p>
          <p>
            As a new nonprofit, engaging new revenue sources was first on The
            Tribune’s agenda — and that engagement included four initial
            components:
          </p>
          <ol className="numbered-list">
            <li>
              Ensure all systems are in place to both accept and account for
              donations, and to acknowledge and cultivate donors appropriately.
              Do this first, before doing the rest. Nonprofits that are waiting
              on IRS designation can get help from a fiscal sponsor.{" "}
            </li>
            <li>
              Encourage subscribers to support The Tribune with a donation,
              emphasizing your ability to do more of the work they appreciate
              with their support.
            </li>
            <li>
              Identify and apply for local and national foundation grants.
            </li>
            <li>
              Creating a short-term and long-term fundraising plan, including
              major gifts, individual giving and support for specific
              initiatives.
            </li>
          </ol>
          <p>
            As The Tribune made its transition to a nonprofit model, we invested
            in the people who could bring their skills and knowledge to the task
            of launching our fundraising plans. Some were hired full-time,
            others were contracted for specific projects.
          </p>
          <p>
            One thing we learned is our staffing needs changed over time. In
            2023, The Tribune has four full-time positions working with
            supporters:
          </p>
          <p>
            <strong>Development Director:</strong> Oversees strategic plan for
            development team. Handles major donor cultivation, stewardship and
            solicitations. Point of contact for Board of Directors and local and
            national foundations. Supervise digital fundraising strategy.
          </p>
          <p>
            <strong>Communication and Development Coordinator: </strong> Manages
            Salesforce CRM used to track and communicate with donors; manages
            events for donors, coordinated communications and services for
            Supporting Subscribers with marketing. Tracks grants and pledge
            reminders.
          </p>
          <p>
            <strong>Marketing Director:</strong> Responsible for overseeing all
            aspects of promotional efforts and marketing strategy, especially
            individual giving strategy. In charge of building brand awareness,
            digital subscription growth, and utilizing Piano to cultivate
            audience retention and further commitments.
          </p>
          <p>
            <strong>Digital Media Manager:</strong> Manages email marketing
            tactics and paid social campaign implementation. Directly
            responsible for creation and maintenance of email and social
            optimization with a heavy focus on growing and engaging the
            Tribune’s paying/supporting audience.
          </p>
          <p>
            Part of donor stewardship is sharing the journalism The Tribune does
            every day — because good journalism inspires people to donate. The
            executive editor is key to that effort, connecting with supporters
            each week to share The Tribune’s story and the value it adds to our
            community.
          </p>
          <hr />
          <h2>Applying for grants</h2>
          <p>
            Searching for and applying for grants takes up a lot of time. Here
            are some guiding recommendations for seeking grants:
          </p>
          <ul>
            <li>
              Pursue grants that are for specific purposes. For example, grants
              for reporting staff, for improved technology, or for operations.
            </li>
            <li>
              Institute accounting policies and systems to track and attribute
              expenses to the appropriate funding source.
            </li>
            <li>
              Look at who in your community is funding similar organizations.
              Think outside the public-radio box to education, healthcare,
              equity, women’s issues, government, etc. Participate in the
              growing number of fundraising training opportunities offered by
              such groups as Report for America, the Lenfest Institute, Knight
              and the News Match, News Revenue Hub and others.
            </li>
            <li>
              Attend local fundraising seminars put on by your state’s nonprofit
              association or universities. This helps you learn what kind of
              fundraising is happening in your area, and it’s a chance to
              network with other nonprofits.
            </li>
          </ul>
          <hr />
          <h2>Setting the rules for donations</h2>
          <p>
            One of the first things a nonprofit news organization needs to do is
            to set a clear policy regarding gifts — what donations the
            organization is willing to accept and from whom. With that, the
            nonprofit should write a statement about the ethics underlying its
            fundraising and journalism. In many ways, the same rules that one
            follows in their journalism also apply to fundraising.
          </p>
          <p>
            The Tribune’s first rule is simple:{" "}
            <strong>
              The Tribune does not accept gifts from any entity or person that
              seeks or expects control over its work.
            </strong>
          </p>
          <p>
            This hasn’t changed from the old days of advertising-supported
            journalism: A company buying a full-page ad was told that they’re
            not buying the paper’s editorial coverage along with it.
          </p>
          <p>
            It’s crucial to avoid even the appearance of someone trying to buy
            our favor. That’s why, for example, The Tribune does not accept
            donations from any current elected official or candidate.
          </p>
          <p>
            Everyone in this business is familiar with the{" "}
            <a
              href="https://www.sltrib.com/news/2021/11/16/shots-fired-film-about/"
              target="_blank"
              rel="noreferrer"
            >
              Society of Professional Journalists Code of Ethics
            </a>
            . If you’re looking to become a nonprofit, you should also read and
            heed the editorial independence guidelines of the{" "}
            <a
              href="https://www.poynter.org/guidelines-2/"
              target="_blank"
              rel="noreferrer"
            >
              Poynter Institute
            </a>{" "}
            and{" "}
            <a
              href="https://inn.org/about/membership-standards/"
              target="_blank"
              rel="noreferrer"
            >
              Institute for Nonprofit News
            </a>
            ,
          </p>
          <p>
            News organizations that demand transparency from others should be as
            transparent as possible themselves.
          </p>
          <p>
            The Tribune sets a clear rule, one in keeping with its journalistic
            transparency: No anonymous gifts over $5,000. Any donor, individual
            or foundation that gives more than $5,000 will be listed on our
            website.
          </p>
          <p>
            The Tribune posts its ethics and{" "}
            <a
              href="https://www.sltrib.com/reports/"
              target="_blank"
              rel="noreferrer"
            >
              gift acceptance policies
            </a>{" "}
            on its website. The site also posts information about The Tribune’s
            sources of revenue, board and advisory council members, federal tax
            returns, and annual reports on the work made possible by public
            support. The Tribune has a{" "}
            <a
              href="https://www.sltrib.com/reports/"
              target="_blank"
              rel="noreferrer"
            >
              section on its website
            </a>{" "}
            with financial and legal information and documents.
          </p>
          <p>
            It’s also important to commit resources to your donation system. You
            need internal infrastructure to receive and track donations. That
            should include funds received, how the gift was generated (email
            campaign, personal call, letter, etc.), any specific purpose the
            donation was meant for, information about the donor, and gift
            acknowledgement.
          </p>
          <p>
            We will stress this over and over again: Saying “thank you” is vital
            if you want to keep donors happy, and keep them giving.
          </p>
          <p>
            The biggest commitment of resources is your people. A successful
            robust development program requires, at a minimum, a full-time
            position.
          </p>
          <p>
            It’s also important to embrace the culture of philanthropy. Think
            about how the entire organization, especially the leadership and
            editor, can be a part of fundraising efforts and understand the
            importance of stewarding donors and serving the public.
          </p>
          <p>
            Through it all, the ideal that guides ethical journalism — to earn
            your community’s trust — also applies to your nonprofit fundraising.
            A good rule of thumb is this: Don’t do anything that, if it was
            somebody else, would make your reporters want to write a story about
            their lack of transparency.
          </p>
          <hr />
          <h2>How to cultivate donors</h2>
          <p>
            In the first days that The Salt Lake Tribune became a nonprofit, we
            expected new donations would flow in with the initial launch — if
            marketing had done its job right — because of the novelty of being
            the first legacy newsroom to become a nonprofit.
          </p>
          <p>
            At first, that worked. Our board helped solicit a few major gifts
            from well-known philanthropists in the community. But for long-term
            success, an organization needs a comprehensive understanding of
            donors — and a plan for stewarding and cultivating them.
          </p>
          <p>
            An example: We received an early gift of $100,000 from one donor,
            who had a personal relationship with a board member. Three years
            later, though, the same donor didn’t want to give annual
            contributions just as a friendly gesture. This donor wanted to see
            our model in place and working.
          </p>
          <p>
            So how does The Tribune keep this donor in the fold? By inviting
            them to different events we’re holding, by sharing stories that
            align with their passions, and by routinely expressing gratitude to
            the donor. That’s how The Tribune can build a relationship with the
            donor that’s not dependent on their friendship to a board member.
          </p>
          <p>
            Here are donor strategies that have worked for The Salt Lake
            Tribune. As always, your mileage may vary.
          </p>
          <ul>
            <li>
              Identify supporters or subscribers who are not current donors, and
              begin outreach and building a relationship with them. Don’t be
              pushy; give the relationship time to flower.
            </li>
            <li>
              Say “thank you” a lot. Develop a plan to communicate with donors
              regularly, just to thank them for their support. Be sincere about
              it — don’t use a “thank you” to ask for another donation.
            </li>
            <li>
              Build a database of subscribers, and from that a database of
              donors. Use it to keep track of who might donate, and how much
              they might be able to donate. It’s unproductive, and potentially
              insulting, to ask for $10,000 donations from someone who can’t
              afford it — and a waste of time asking for small donations from
              someone who can afford more.
            </li>
            <li>
              Find the staffer in your development department who will be the
              point person for major donors — and give that staff person the
              support to do regular check-ins.
            </li>
            <li>
              Keep in mind the 80/20 rule: 80% of your money will likely come
              from the top 20% of your donors. You will still want to make broad
              pitches to attract smaller donors — but cultivating the folks at
              the top requires more one-on-one attention.
            </li>
            <li>
              People want different things from their news outlet. Get to know
              what your donors and potential donors are interested in —
              politics, sports, culture, environment, etc. When you publish
              significant stories or projects in those areas, target emails to
              the donors who want to know more about those topics.
            </li>
            <li>
              With foundations and other granting bodies, build relationships
              with the organization’s point of contact before you start asking
              for money or applying for grants. Send updates from time to time,
              without asking for anything. As with individual donors, it’s about
              building a relationship.
            </li>
          </ul>
          <br></br>
          <p>
            There’s one aspect of cultivating donors that will require something
            of a mental shift among your journalists: Sharing your work with
            those who have means to support it.
          </p>
          <p>
            As a nonprofit, you’re now a community resource. And that means
            getting out into the community.
          </p>
          <p>
            Make an event out of it. Invite a group of prospective donors to
            hear a Q&A with your political columnist, or a sports or culture
            reporter. Most journalists with any experience have a standard
            five-minute speech they give to civic groups — nothing
            controversial; a just-the-facts accounting of what they do on a
            daily basis.
          </p>
          <p>
            Listening sessions are another way to connect with your public and
            your potential donors. Have an editor and a couple of reporters meet
            with leaders in different subsets of your community, and hear what
            they have to say about your coverage (or lack of it) on the topics
            that matter most to them.
          </p>
          <p>
            For bigger donors, where one-on-one contact is required, we leave
            that to the executive editor — who, being briefed in advance, can
            speak to the donor’s interests and concerns.
          </p>
          <p>
            The key for the journalists pressed into service is that we’re here
            to listen to donors, but we’re not here to do their bidding. The
            rules of journalistic independence need to be made clear early and
            often — and donors will soon get the message that donating to a news
            outlet isn’t the same as buying an ad.
          </p>
          <p>
            The community is uppermost in the minds of every reporter, editor,
            photographer and artist as we report, write, illustrate and edit
            stories for The Tribune. From the beginning of the process, the
            questions we’re always asking are “why does this matter?” and “who
            is this for?” — questions that put the community, the people reading
            and watching our journalism, front and center.{" "}
          </p>
          <hr />
          <h2>Community involvement</h2>
          <img
            src="/Rick.jpg"
            className="responsive center"
            alt="community-rick"
            loading="lazy"
          ></img>
          <p>
            Becoming a nonprofit means becoming focused on the community. That
            means connecting with people in what we hope is a more meaningful
            capacity than when we were a for-profit. In 2020, The Tribune talked
            to focus groups, so we could learn more about the community’s reader
            habits and what motivated them to become subscribers.
          </p>
          <p>
            We also invited Tribune subscribers and community stakeholders to
            talk to us in focus groups. They gave us strong feedback on how we
            should keep our donations and funding as transparent as possible.
          </p>
          <p>
            And we conducted public surveys to find out what interested them
            about The Tribune’s print editions, e-editions and website —
            measuring public support for The Tribune, learning what barriers
            kept people from subscribing, and taking in suggestions for
            improvement and feedback on the nonprofit model.
          </p>
          <p>
            The Tribune has also joined organizations like the Institute for
            Nonprofit News (INN) in order to both contribute to the shared
            practice they facilitate, and to gain access to valuable resources
            available to nonprofit news organizations.
          </p>
          <p>
            Efforts to listen to the community aren’t a one-off, though. They
            will — or, at least, should — become part of the nonprofit’s DNA.
          </p>
          <p>
            In 2022, as an example, The Tribune conducted a listening session
            with residents of Salt Lake County’s west side, and another with
            members of the Spanish-speaking community. Two reporters did an
            informal “walking version” of a listening session with attendees at
            a recent Silicon Slopes summit, to gauge what people in Utah’s tech
            sector wanted to see in The Tribune.
          </p>
          <p>
            Sessions like this can foster new champions for your work, get your
            reporters’ faces and your outlet’s name out to more people, and
            improve your journalism.
          </p>
          <p>
            For example, the listening session with west-side residents sparked
            conversations about our coverage of the Inland Port — a proposed
            west-side hub for truck and rail shipping in Salt Lake County. The
            west-side residents complained that the coverage focused on the
            negative aspects of the port project (pollution, added traffic,
            etc.), and mostly quoted east-siders, and didn’t talk to west-side
            residents who are actually excited about the port and the money it
            might bring to their part of the Salt Lake Valley.
          </p>
          <p>
            As part of our First Amendment Society and Supporting Subscriber
            member benefits, The Tribune offers quarterly events that pull the
            curtain back on the newsroom and its projects. We present some
            events in person and some virtually, so more donors and subscribers
            can take part. Some examples: Our Statewatch team gives a preview of
            the election and a wrap-up of the Utah Legislature’s annual session;
            our sports desk provides previews of the Utah Jazz and college
            football seasons; and we host conversations with editorial
            cartoonist Pat Bagley.
          </p>
          <p>
            The Tribune has added opportunities for engagement and education,
            including:
          </p>
          <ul>
            <li>
              Educational material about The Tribune’s transition to a
              nonprofit, including details on our nonprofit model and an FAQs
              page. The information covers the definition of a nonprofit, ways
              to donate, how the money is spent, and how the organization is
              run. There also is a list of The Tribune’s management, and the
              volunteer Board of Directors.
            </li>
            <li>
              A story “Tip Line”, where readers can call or email story ideas.
              Readers are also encouraged to contact reporters directly, or
              provide information anonymously.
            </li>
            <li>
              More educational material about the role of local journalism in
              democracy, how news is gathered and reported, and how to watch out
              for “fake news.” These include a section for educators.
            </li>
            <li>
              Free digital access, through libraries and for all high school
              seniors.
            </li>
            <li>
              Regular outreach through social media, asking people for input on
              stories ranging from experiences with COVID-19 vaccines to where
              the worst fast-food drive-thrus are.
            </li>
          </ul>
          <br></br>
          <br></br>
          <br></br>
          <div>
            <p>
              <strong>For further reading:</strong>
            </p>
            <p>
              •{" "}
              <a
                href="https://inn.org/research/guides/conversion-guide-unlocked/"
                target="_blank"
                rel="noreferrer"
              >
                “Quick Guide to Converting a For-profit to Nonprofit News
                Outlet”
              </a>
              , Institute for Nonprofit News.
            </p>
          </div>
          <div className="next-section">
            <h4>
              Next Section: <Link to="/governance">Governance</Link>
            </h4>
          </div>
          <hr />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default MakingTransition;
