import React, { useState, useEffect, useCallback } from "react";
import "./Calculator.scss";

const Calculator = () => {
  const [calcState, setCalcState] = useState({
    adRevenue: "0",
    donationRevenue: "0",
    foundationAndGrantsRevenue: "0",
    corpUnderwritingRevenue: "0",
    eventProgramInvestmentRevenue: "0",
    subscriptionsRevenue: "0",
    taxableIncomeRevenue: "0",
    percentOfIncomeAdvertising: "0",
  });

  const [message, setMessage] = useState(
    "Your results from the calculation above will display here. Enter your revenue data points to begin."
  );

  // const [submit, setSubmit] = useState(false);

  useEffect(() => {
    localStorage.setItem("calcState", calcState);
  }, [calcState]);

  const calculatePercentOfIncomeAdvertising = useCallback(() => {
    if (
      Number(calcState.adRevenue) +
        Number(calcState.donationRevenue) +
        Number(calcState.foundationAndGrantsRevenue) +
        Number(calcState.corpUnderwritingRevenue) +
        Number(calcState.eventProgramInvestmentRevenue) +
        Number(calcState.subscriptionsRevenue) +
        Number(calcState.taxableIncomeRevenue) ===
      0
    ) {
      return;
    }

    const decimal =
      Number(calcState.adRevenue) /
      (Number(calcState.adRevenue) +
        Number(calcState.donationRevenue) +
        Number(calcState.foundationAndGrantsRevenue) +
        Number(calcState.corpUnderwritingRevenue) +
        Number(calcState.eventProgramInvestmentRevenue) +
        Number(calcState.subscriptionsRevenue) +
        Number(calcState.taxableIncomeRevenue));

    calcState.percentOfIncomeAdvertising = (decimal * 100).toFixed(2);
  }, [calcState]);

  useEffect(() => {
    calculatePercentOfIncomeAdvertising();
  }, [calculatePercentOfIncomeAdvertising]);

  useEffect(() => {
    if (
      calcState.adRevenue === "0" &&
      calcState.donationRevenue === "0" &&
      calcState.foundationAndGrantsRevenue === "0" &&
      calcState.corpUnderwritingRevenue === "0" &&
      calcState.eventProgramInvestmentRevenue === "0" &&
      calcState.subscriptionsRevenue === "0" &&
      calcState.taxableIncomeRevenue === "0" &&
      calcState.percentOfIncomeAdvertising === "0"
    ) {
      return;
    }
    if (calcState.percentOfIncomeAdvertising < 33.33) {
      setMessage(`Your advertising revenue is ${calcState.percentOfIncomeAdvertising}% of your total revenue.


      Congratulations! That’s less than a third of your total revenue — which is where the IRS wants you to be if you’re a nonprofit.
      `);
    } else if (
      calcState.percentOfIncomeAdvertising > 33.33 &&
      calcState.percentOfIncomeAdvertising < 45
    ) {
      setMessage(`Your advertising revenue is ${calcState.percentOfIncomeAdvertising}% of your total revenue.


      Not bad. That’s more than the IRS allows for a nonprofit — but you’re not too far from the mark. Thankfully, the IRS gives a nonprofit five years to get to that one-third level.
      `);
    } else {
      setMessage(`Your advertising revenue is ${calcState.percentOfIncomeAdvertising}% of your total revenue.


      Interesting. That’s well over the level the IRS allows for a nonprofit. You would need to make some significant changes to get to the one-third level that the IRS requires. Are you sure you want your organization to become a nonprofit?
      `);
    }
  }, [calcState]);

  return (
    <div className="calculator-container">
      <h1>Nonprofit Calculator</h1>
      <h2>Find out if you're able to become a nonprofit</h2>
      <div>Enter your data points</div>
      <form>
        <div className="form-row">
          <label>
            Net revenue taken in by advertising
            <input
              id="first"
              className="form-entry"
              name="revenue-advertising"
              type="number"
              required
              value={calcState.adRevenue}
              onChange={(e) =>
                setCalcState((s) => ({ ...s, adRevenue: e.target.value }))
              }
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            Revenue taken by donations from individuals
            <input
              id="second"
              className="form-entry"
              name="revenue-individuals"
              type="number"
              required
              value={calcState.donationRevenue}
              placeholder="donation revenue"
              onChange={(e) =>
                setCalcState((s) => ({ ...s, donationRevenue: e.target.value }))
              }
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            Revenue from foundation and corporate grants
            <input
              id="third"
              className="form-entry"
              type="number"
              name="revenue-grants"
              required
              value={calcState.foundationAndGrantsRevenue}
              onChange={(e) =>
                setCalcState((s) => ({
                  ...s,
                  foundationAndGrantsRevenue: e.target.value,
                }))
              }
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            Revenue from Corporate Underwriting
            <input
              id="fourth"
              className="form-entry"
              type="number"
              name="revenue-corporate-underwriting"
              required
              value={calcState.corpUnderwritingRevenue}
              onChange={(e) =>
                setCalcState((s) => ({
                  ...s,
                  corpUnderwritingRevenue: e.target.value,
                }))
              }
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            Revenue from Events, and program-related income or investments
            (tax-deductible)
            <input
              id="fifth"
              className="form-entry"
              name="revenue-events"
              type="number"
              required
              value={calcState.eventProgramInvestmentRevenue}
              onChange={(e) =>
                setCalcState((s) => ({
                  ...s,
                  eventProgramInvestmentRevenue: e.target.value,
                }))
              }
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            Revenue from Subscriptions
            <input
              id="sixth"
              className="form-entry"
              name="revenue-subscriptions"
              type="number"
              required
              value={calcState.subscriptionsRevenue}
              onChange={(e) =>
                setCalcState((s) => ({
                  ...s,
                  subscriptionsRevenue: e.target.value,
                }))
              }
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            Net revenue from events (not tax-deductible) and other taxable
            income
            <input
              id="seventh"
              className="form-entry"
              type="number"
              name="revenue-events-other"
              required
              value={calcState.taxableIncomeRevenue}
              onChange={(e) =>
                setCalcState((s) => ({
                  ...s,
                  taxableIncomeRevenue: e.target.value,
                }))
              }
            />
          </label>
        </div>
        {/* <button
          className="input-submit"
          onClick={(e) => {
            e.preventDefault();
            if (
              calcState.adRevenue === "0" &&
              calcState.donationRevenue === "0" &&
              calcState.foundationAndGrantsRevenue === "0" &&
              calcState.corpUnderwritingRevenue === "0" &&
              calcState.eventProgramInvestmentRevenue === "0" &&
              calcState.subscriptionsRevenue === "0" &&
              calcState.taxableIncomeRevenue === "0" &&
              calcState.percentOfIncomeAdvertising === "0"
            ) {
              return;
            }
            setSubmit(true);
          }}
        >
          Submit
        </button> */}
        <div className="form-row">
          <h1>Your results</h1>
          {<p className="form-row">{message}</p>}
        </div>
      </form>
    </div>
  );
};

export default Calculator;
