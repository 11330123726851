const Video = () => {
    return (
      <video controls width="100%">
        <source src="https://dp8zd7xoopwco.cloudfront.net/033123_PlaybookVideo_04.mp4" type="video/mp4"
        />
        Sorry, your browser doesn't support videos.
      </video>
    );
  };
  
  export default Video;