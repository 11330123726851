import React from "react";
import DocumentMeta from "react-document-meta";
import "./Acknowledgements.scss";

const Acknowledgements = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Acknowledgements",
    description:
      "The Salt Lake Tribune thanks everyone who took part in the creation of our Nonprofit Playbook.",
    canonical: "https://playbook.sltrib.com/acknowledgements",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "nonprofit, nonprofit playbook, the salt lake tribune, nonprofit newsroom,",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="acknowledgements">
        <div className="acknowledgements-content">
          <h1>Acknowledgements</h1>
          <p>
            Among the writers and contributors to this second edition are The
            Salt Lake Tribune’s Elias Cunningham, Chris Stegman, Jessica
            Sweeney, Ian Swenson and Danyelle White. Christopher Cherrington
            designed the graphics. Chris Samuels compiled the photos. Antonio
            Ramirez and Alex Partida designed the website.
          </p>
          <p>
            Sean P. Means edited this second edition, with editorial support and
            oversight from Tribune executive editor Lauren Gustus.
          </p>
          <p>
            This second edition borrows from the first one, which was largely
            written by Erika Wilson and Fraser Nelson. That edition included
            interviews with: Sue Cross from the Institute of Nonprofit News,
            Annie Madonia from the Lenfest Institute, Terry Quinn from the Texas
            Tribune, Tim Griggs, and The Tribune’s Liz Morales and Jennifer
            Napier-Pearce.
          </p>
          <p>
            Thanks to everyone who contributed to the creation of this playbook,
            and especially to the{" "}
            <a
              href="https://knightfoundation.org/"
              target="_blank"
              rel="noreferrer"
            >
              Knight Foundation
            </a>{" "}
            for its support of this work.
          </p>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Acknowledgements;
