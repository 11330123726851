import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/Header/Navbar";
import Acknowledgements from "./pages/acknowledgements/Acknowledgements";
import AudienceRevenue from "./pages/audience-revenue/AudienceRevenue";
import BoardOfDirectors from "./pages/board/BoardDirectors";
import Collaborations from "./pages/collaborations/Collaborations";
import FinalThoughts from "./pages/final-thoughts/FinalThoughts";
import Homepage from "./pages/homepage/Homepage";
import Introduction from "./pages/introduction/Introduction";
import MakingTransition from "./pages/making-transition/MakingTransition";
import NonprofitBasics from "./pages/nonprofit-basics/NonprofitBasics";
import NonprofitBusiness from "./pages/nonprofit-business/NonprofitBusiness";
import Resources from "./pages/resources/Resources";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-WJJTW7K'
}
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <div className="app">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/nonprofit-basics" element={<NonprofitBasics />} />
          <Route path="/will-it-work-for-you" element={<NonprofitBusiness />} />
          <Route path="/making-the-transition" element={<MakingTransition />} />
          <Route path="/governance" element={<BoardOfDirectors />} />
          <Route path="/building-support" element={<AudienceRevenue />} />
          <Route path="/collaborations" element={<Collaborations />} />
          <Route path="/final-thoughts" element={<FinalThoughts />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/acknowledgements" element={<Acknowledgements />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
