import React from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "./Resources.scss";

const Resources = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Will it Work for You?",
    description:
      "The Salt Lake Tribune presents how we make revenue as a nonprofit. Find out if our methods work for your news organization.",
    canonical: "https://playbook.sltrib.com/resources",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "nonprofit, nonprofit playbook, the salt lake tribune, nonprofit newsroom,",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="resources">
        <div className="resources-content">
          <h1>Resources</h1>
          <h2>Resources for Further Reading</h2>
          <p>
            <a
              href="https://inn.org/news-startup-resources/"
              target="_blank"
              rel="noreferrer"
            >
              News Startup Resources
            </a>{" "}
            <a href="https://inn.org/" target="_blank" rel="noreferrer">
              Institute for Nonprofit News
            </a>
            , 2023
          </p>

          <p>
            <a
              href="https://inn.org/research/inn-index/inn-index-2022/"
              target="_blank"
              rel="noreferrer"
            >
              INN Index 2022: Enduring in crisis, surging in local communities
            </a>
            {"  "}
            <a href="https://inn.org/" target="_blank" rel="noreferrer">
              Institute for Nonprofit News
            </a>
            , July 27, 2022
          </p>
          <p>
            <a
              href="https://inn.org/research/lessons-from-local-news-founders/"
              target="_blank"
              rel="noreferrer"
            >
              Lessons from Startups: 10 Founders of Local Nonprofit News Outlets
              Share Their Motivations, Struggles
            </a>
            {"  "}
            <a href="https://inn.org/" target="_blank" rel="noreferrer">
              Institute for Nonprofit News
            </a>
            , written by Katrina Janco, July 15, 2022
          </p>
          <p>
            <a
              href="https://knightfoundation.org/reports/investments-in-local-news-sustainability/"
              target="_blank"
              rel="noreferrer"
            >
              Investments in Local News Sustainability: Early Learnings and
              Insights
            </a>{" "}
            <a
              href="https://knightfoundation.org/"
              target="_blank"
              rel="noreferrer"
            >
              The Knight Foundation
            </a>
            , May 21, 2022
          </p>
          <p>
            <a
              href="https://www.americanpressinstitute.org/category/reader-revenue/"
              target="_blank"
              rel="noreferrer"
            >
              Reader Revenue Toolkit
            </a>{" "}
            <a
              href="https://www.americanpressinstitute.org/"
              target="_blank"
              rel="noreferrer"
            >
              American Press Institute
            </a>
            , 2022
          </p>
          <p>
            <a
              href="https://ecosystems.democracyfund.org/"
              target="_blank"
              rel="noreferrer"
            >
              A Guide to Assessing Your Local News Ecosystem
            </a>{" "}
            <a
              href="https://democracyfund.org/"
              target="_blank"
              rel="noreferrer"
            >
              Democracy Fund
            </a>
            , 2022
          </p>
          <p>
            <a
              href="https://www.niemanlab.org/2021/11/now-nonprofit-the-salt-lake-tribune-has-achieved-something-rare-for-a-local-newspaper-financial-sustainability/?s=03"
              target="_blank"
              rel="noreferrer"
            >
              Now nonprofit, The Salt Lake Tribune has achieved something rare
              for a local newspaper: financial sustainability Nieman Lab
            </a>
            , written by Sarah Scire, November 29, 2021
          </p>
          <p>
            <a
              href="https://knightfoundation.org/articles/how-creative-ownership-structures-can-help-local-news-publishers-stay-local/"
              target="_blank"
              rel="noreferrer"
            >
              How Creative Ownership Structures Can Help Local News Publishers
              Stay Local
            </a>{" "}
            <a
              href="https://knightfoundation.org/"
              target="_blank"
              rel="noreferrer"
            >
              The Knight Foundation
            </a>
            , written by Mark Glaser, October 6, 2021
          </p>
          <p>
            <a
              href="https://propublica.gitbook.io/collaborative/"
              target="_blank"
              rel="noreferrer"
            >
              Collaborative Data Journalism Guide
            </a>{" "}
            <a
              href="https://www.propublica.org/"
              target="_blank"
              rel="noreferrer"
            >
              ProPublica
            </a>
            , written by Rachel Glickhouse, 2020
          </p>
          <hr />
          <h2>Resources on Innovative Business Models</h2>
          <p>
            10 news publishers that do it right:{" "}
            <a
              href="https://www.editorandpublisher.com/stories/eps-2023-class-of-10-news-publishers-that-do-it-right,242909"
              target="_blank"
              rel="noreferrer"
            >
              2023 edition
            </a>{" "}
            and{" "}
            <a
              href="https://www.editorandpublisher.com/stories/10-news-publishers-that-do-it-right,219623?newsletter=219852"
              target="_blank"
              rel="noreferrer"
            >
              2022 edition
            </a>
            ,{" "}
            <a
              href="https://www.editorandpublisher.com/"
              target="_blank"
              rel="noreferrer"
            >
              Editor & Publisher
            </a>
            , written by Robin Blinder, March 31, 2023, and March 1, 2022
          </p>
          <p>
            <a
              href="https://www.niemanlab.org/2023/02/the-future-of-local-news-is-civic-information-not-declining-legacy-systems-new-report-says/"
              target="_blank"
              rel="noreferrer"
            >
              The future of local news is “civic information,” not “declining
              legacy systems,” says new report
            </a>{" "}
            <a
              href="https://www.niemanlab.org/"
              target="_blank"
              rel="noreferrer"
            >
              Nieman Lab
            </a>
            , written by Laura Hazard Owen, February 2, 2023
          </p>
          <p>
            <a
              href="https://productdragon.org/article/entrepreneurship/social-entrepreneurship-explained-examples/"
              target="_blank"
              rel="noreferrer"
            >
              Social Entrepreneurship Explained With Examples
            </a>{" "}
            <a
              href="https://productdragon.org/"
              target="_blank"
              rel="noreferrer"
            >
              Product Dragon
            </a>
            , written by Alexander Frakking, January 20, 2023
          </p>
          <hr />
          <h2>Contact Us</h2>
          <p>
            If you have questions about The Tribune or its transition to a
            nonprofit, please email{" "}
            <a href="mailto:playbook@sltrib.com">playbook@sltrib.com</a>
          </p>
          <div className="next-section">
            <h4>
              Next Section: <Link to="/acknowledgements">Acknowledgements</Link>
            </h4>
          </div>
          <hr />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Resources;
