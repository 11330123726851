import React from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "./NonprofitBasics.scss";

const NonprofitBasics = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Nonprofit Basics",
    description:
      "Find out how The Salt Lake Tribune made the transition into a nonprofit and whether it works for your newsroom.",
    canonical: "https://playbook.sltrib.com/nonprofit-basics",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "nonprofit, nonprofit playbook, the salt lake tribune, nonprofit newsroom,",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="nonprofit-basics">
        <div className="nonprofit-basics-content">
          <h1>Nonprofit Basics</h1>
          <p>
            To qualify as a tax-exempt nonprofit 501(c)(3) organization under
            the U.S. tax code, an organization must meet the following
            requirements, as defined by the Internal Revenue Service:
          </p>
          <ol className="numbered-list" style={{ "--length": "5" }}>
            <li>
              The entity must be organized and operated to solely pursue an
              exempt purpose. Such purposes include, according to the statute,
              “charitable, religious, educational, scientific, literary, testing
              for public safety, fostering national or international amateur
              sports competition, and preventing cruelty to children or
              animals.”
            </li>
            <li style={{ "--i": "1" }}>
              To stay true to the nonprofit’s purpose (see point 1), the entity
              must not be organized and operated to benefit private interests —
              and none of its net earnings can result in benefit to any private
              shareholder or individual involved in the organization’s
              activities.
            </li>
            <li>
              A 501(c)(3) organization must not endorse candidates or
              electioneer. According to the IRS, this means that the
              organization “may not attempt to influence legislation as a
              substantial part of its activities and it may not participate in
              any campaign activity for or against political candidates.” This
              does not, however, preclude an organization from publishing
              editorial and opinion pieces.
            </li>
          </ol>
          <p>
            We’re going to say this early and often: Check with your own lawyers
            and accountants before moving forward on becoming a nonprofit.
          </p>
          <img
            src="/irs.jpg"
            className="responsive center"
            alt="IRS Building"
            loading="lazy"
          ></img>
          <hr />
          <h2>What does it mean to be a nonprofit newspaper?</h2>
          <p>
            From a financial standpoint, the two key components that
            differentiate a nonprofit from a for-profit entity are tax status
            and ownership.
          </p>
          <ul className="newspaper-list">
            <li>
              Ownership: The nonprofit newspaper is not owned by any person,
              group of people, or private entity. It is a public asset, owned by
              the community it serves. In lieu of an owner or shareholders, the
              nonprofit newspaper is overseen by an independent board of
              directors, who reflect and represent the community.
            </li>
            <li>
              Tax Status: As a tax-exempt 501(c)3 charitable organization, all
              mission-related - income and revenue is tax-exempt. However,
              non-mission related income can be taxed. This is called Unrelated
              Business Income Taxation (UBIT). The nonprofit newspaper can
              accept donations directly from the public and from foundations,
              and these donations are tax-deductible.
            </li>
          </ul>
          <p>
            The short answer: A nonprofit organization answers to its
            stakeholders — the community.
          </p>
          <hr />
          <h2>How does tax status affect business structure? </h2>
          <p>
            Changing your news organization’s tax status is not the same as
            changing its business structure. They may coincide, but they are not
            related.
          </p>
          <p>
            The connection between tax status and business structure lies in the
            difference between allowable revenue streams for each. For example,
            the advertising revenue that for-profit businesses regularly receive
            is treated differently when a nonprofit gets it — if the advertising
            promotes a product, it’s considered “unrelated business income”; and
            if it is simply underwriting the nonprofit organization, it’s
            considered “public support.” In other words, it can get complicated.{" "}
          </p>
          <p>
            Some companies may decide to take the middle path, between
            for-profit and nonprofit, by registering as a benefit corporation
            (B-corp) — a certification and legal designation used by for-profit
            companies that are focused on a social or environmental mission.
            While maintaining their for-profit status and primary accountability
            to shareholders, B-corps also pay attention to their stakeholders
            and mission.
          </p>
          <hr></hr>
          <h2>Comparing the three models</h2>
          <p>
            Here’s a breakdown of how the three models — for-profit, B-Corp and
            nonprofit 501(c)(3) — compare in terms of revenue opportunities and
            limitations:
          </p>
          <h3>For-profit (LLC, etc.):</h3>
          <p>
            <strong>Bottom line: </strong>
            The primary purpose is to earn profit, is subject to taxation on all
            revenue, and relies on sales and private investments.
          </p>
          <h4>Revenue opportunities:</h4>
          <ul className="newspaper-list">
            <li>Sale of goods or services (subscriptions, advertising).</li>
            <li>Private capital.</li>
            <li>Market investments.</li>
          </ul>
          <h4>Limitations and considerations:</h4>
          <ul className="newspaper-list">
            <li>No income is tax-exempt.</li>
            <li>No limitations on spending.</li>
            <li> No limitations on political speech.</li>
            <li> Not attached to a social mission.</li>
          </ul>
          <br></br>
          <h3> B-corp (for profit):</h3>
          <p>
            <strong>Bottom line: </strong>
            Operates as a for-profit business where all revenue is subject to
            taxation, but also prioritizes a social mission.
          </p>
          <h4>Revenue opportunities:</h4>
          <ul className="newspaper-list">
            <li>Sale of goods or services.</li>
            <li>Private capital.</li>
            <li>Market investments.</li>
            <li>Non-tax-deductible donations.</li>
            <li>Grants from some foundations.</li>
            <li>Program-related investments.</li>
          </ul>
          <h4>Limitations and considerations:</h4>
          <ul className="newspaper-list">
            <li>No income is tax exempt.</li>
            <li>No limitations on political speech.</li>
            <li>Held to a social mission in addition to profit.</li>
            <li>Many grants are only available to nonprofits.</li>
            <li>
              Must complete an impact assessment report every three years to
              maintain certification.
            </li>
          </ul>
          <br></br>
          <h3>Nonprofit 501(c)(3):</h3>
          <p>
            <strong>Bottom line:</strong> The primary purpose is to fulfill a
            social mission, and the majority of revenue is tax-exempt.
          </p>
          <h4>Revenue opportunities:</h4>
          <ul className="newspaper-list">
            <li>Tax-deductible donations.</li>
            <li>Private/public grants.</li>
            <li>
              Taxable unrelated business income through goods and services
              (primarily advertising).
            </li>
            <li>Program-related investment.</li>
            <li> Corporate underwriting and sponsorships.</li>
          </ul>
          <h4>Limitations and considerations:</h4>
          <ul className="newspaper-list">
            <li>No shareholder equity or private capital.</li>
            <li>Donors have no ownership or influence.</li>
            <li> Higher standard for transparency and accountability.</li>
            <li>Limitations on political speech.</li>
            <li> All income must be reinvested in the organization.</li>
            <li>
              Required to prove it is serving its mission through tax filing
              (form 990).
            </li>
          </ul>
          <br></br>
          <hr></hr>
          <br></br>
          <h2>
            How does a newspaper change ownership when transitioning to
            nonprofit status?
          </h2>
          <p>There are multiple routes:</p>
          <p>
            <strong>Route 1:</strong>{" "}
            <em>
              A new nonprofit corporation is created, and the pre-existing
              for-profit company donates the newspaper assets and masthead to
              the nonprofit.
            </em>
          </p>

          <p>
            Paul Huntsman bought The Tribune in 2016 and donated The Tribune’s
            masthead and assets to the newly created charity. This ensured that
            liabilities weren’t transferred to the new entity, and avoided
            complications from a pre-existing joint operating agreement.
          </p>
          <p>
            For an owner, especially for a family-owned newspaper, giving away
            ownership often is more than a business decision. It can be
            personal. The paper is a long-standing asset, and owners may carry
            years, even decades, of personal involvement and emotional
            investment, and that can complicate the decision process. The power
            to endorse candidates goes away, too — which you may find a
            disadvantage or a relief.
          </p>
          <p>
            <strong>Route 2:</strong>{" "}
            <em>
              The original entity is converted to a nonprofit organization.
            </em>
          </p>
          <ul className="newspaper-list">
            <li>
              Not all states allow for statutory conversions of business
              entities. Check the laws where you are.
            </li>
            <li>
              Depending on whether the newspaper is an incorporated business or
              held as an unincorporated business (such as a sole
              proprietorship), the process for conversion will be different.
            </li>
            <li>
              For a detailed description of this process, see this{" "}
              <a
                href="https://inn.org/research/guides/conversion-guide/"
                target="_blank"
                rel="noreferrer"
              >
                step-by-step guide to converting a business from for-profit to
                nonprofit
              </a>
              . The Institute for Nonprofit News is another great resource.
            </li>
          </ul>
          <div>
            <br></br>
            <br></br>
            <br></br>
            <p>
              <strong>For further reading:</strong>
            </p>
            <p>
              •{" "}
              <a
                href="https://localnewsinitiative.northwestern.edu/posts/2022/01/19/salt-lake-nonprofit/"
                target="_blank"
                rel="noreferrer"
              >
                “Going Nonprofit Puts Salt Lake Tribune on Path to
                Sustainability”
              </a>
              , Emily Anderson, Northwestern/Medill Local News Initiative, Jan.
              19, 2022. (Full disclosure: In July 2022, after getting her
              master’s degree at Medill, Emily Anderson Stern joined The
              Tribune’s staff as a government reporter.)
            </p>
          </div>
          <div className="next-section">
            <h4>
              Next Section:{" "}
              <Link to="/will-it-work-for-you">Will It Work for You?</Link>
            </h4>
          </div>
          <hr />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default NonprofitBasics;
