import React from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "./FinalThoughts.scss";

const FinalThoughts = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Final Thoughts",
    description:
      "The Salt Lake Tribune's final thoughts on becoming a nonprofit and way to reach out to the Tribune for more questions.",
    canonical: "https://playbook.sltrib.com/final-thoughts",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "nonprofit, nonprofit playbook, the salt lake tribune, nonprofit newsroom, nonprofit news, news collaborations",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="final-thoughts">
        <div className="final-thoughts-content">
          <h1>Final Thoughts</h1>
          <p>
            We don’t have all the answers. Nor is there one right path for local
            news. But if you’ve got questions after reading the playbook or if
            you would like more information on any of the topics we covered
            here, we’re happy to connect. Email{" "}
            <a href="mailto:lgustus@sltrib.com">lgustus@sltrib.com</a> to talk
            to our editor, Lauren Gustus.
          </p>
          <h3>
            Before you go, here is some final practical advice to consider if
            you’re thinking about going down the nonprofit path:
          </h3>
          <ul>
            <li>
              What is the outcome you want? What measures can you put around it?
              Will a structural change to nonprofit status get you there?
              Understanding where you’re trying to go before you get started may
              sound obvious, but clearly defining what success looks like for
              you will sharpen the process.
            </li>
            <li>
              Understand how your mission will change if you become a nonprofit.
              Have conversations with your staff. This only works if the
              journalists in your organization are invested in making the
              transition, and everyone should understand how your value
              proposition will evolve.
            </li>
            <li>
              Understand and evaluate the trade-offs. There are significant
              advantages to being owned by the community. But losing the ability
              to endorse candidates in political races may matter to you, or the
              one-third rule could be a significant barrier to entry (remember,
              though, you’ve got 5 years!).
            </li>
            <li>
              Run the numbers. Look at your current revenue sources, and measure
              how they would change if you became a nonprofit. Talk to your
              accountant. Run multiple scenarios including optimistic and
              pessimistic forecasts.{" "}
            </li>
            <li>
              How does your community (city, region or state) that you serve
              feel about your news organization? As we’ve said over and over,
              they will be your owners. Are they rooting for you? Are they
              frustrated by what they see is a lack of coverage? What will
              become of your current owners (are they local)? Start talking to
              the key stakeholders, to get a sense of what support might exist
              for moving to a nonprofit. You want champions in your corner the
              day you go public.
            </li>
          </ul>
          <div className="next-section">
            <h4>
              Next Section: <Link to="/resources">Resources</Link>
            </h4>
          </div>
          <hr></hr>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default FinalThoughts;
