import React from "react";
import SocialLink from "../../components/SocialLinks";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "./Homepage.scss";

const Homepage = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook",
    description:
      "Our journalism has changed. The nation’s first major metro nonprofit newsroom, the Salt Lake Tribune shares what’s working and lessons learned",
    canonical: "https://playbook.sltrib.com/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "nonprofit, nonprofit playbook, the salt lake tribune, nonprofit newsroom, nonprofit news, news collaborations",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <>
        <div className="homepage">
          <div className="nav-left">
            <div className="nav-line"></div>
            <SocialLink
              class="fa fa-facebook social-link"
              link="https://www.facebook.com/saltlaketribune/"
            />
            <SocialLink
              class="fa fa-instagram social-link"
              link="https://www.instagram.com/sltrib/"
            />
            <SocialLink
              class="fa fa-twitter social-link"
              link="https://twitter.com/sltrib/"
            />
            <div className="nav-line"></div>
          </div>
          <div className="main-content">
            <div className="info-section">
              <h1>The Salt Lake Tribune Nonprofit Playbook</h1>
              <p>Truth. Empowerment. Community. Pulitzer Prize Winner.</p>
              <p>
                Our journalism has changed. The nation’s first major metro
                nonprofit newsroom - now sustainable - shares what’s working and
                lessons learned.
              </p>
            </div>
            {/* <div className="homepage-img-section">
            <img
              src="/courtney-indigenous.jpg"
              alt="newspaper"
              className="newspaper"
              width="400"
              height="350"
            />
          </div> */}
          </div>
          <div className="next-section">
            <h4>
              <span>Next Section:</span>{" "}
              <Link to="/introduction">Introduction</Link>
            </h4>
          </div>
        </div>
      </>
    </DocumentMeta>
  );
};

export default Homepage;
