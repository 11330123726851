import React from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "./AudienceRevenue.scss";

const AudienceRevenue = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Building Support",
    description:
      "As a news outlet, the Salt Lake Tribune explains the realtionship between our audience and how to grow readers into subscribers and subscribers into donors.",
    canonical: "https://playbook.sltrib.com/building-support",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "nonprofit, nonprofit playbook, the salt lake tribune, nonprofit newsroom, nonprofit news",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="audience-revenue">
        <div className="audience-revenue-content">
          <h1>Building Support</h1>
          <p>
            As a news outlet — whether for-profit or nonprofit — you want people
            to engage with your work.
          </p>
          <p>
            As a for-profit news outlet, the relationship with the audience is
            clearly transactional. People purchase a subscription, they get the
            news from you.
          </p>
          <p>
            At a nonprofit, the audience — the stakeholders — are more diverse.
            And because you’re relying on donations as well as sales, the
            relationship is more complicated.
          </p>
          <p>
            With the free-access model, it’s hard to convince an audience to pay
            money for a free product. Free-access nonprofit news outlets will
            often change what the “product” is. The “product” is the social
            mission.
          </p>
          <p>
            Listen to a public radio station during pledge drives, and they
            regularly pitch the value and importance of their mission. At The
            Salt Lake Tribune, we want philanthropy and Supporting Subscribers’
            contributions to cover the majority of costs associated with the
            newsroom. The Supporting Subscriber program (which we will describe
            shortly) combines the transactional relationship with the
            mission-driven relationship.
          </p>
          <hr />
          <h2>Reader-to-donor pathway</h2>
          <img
            src="/reader-to-donor.jpg"
            className="responsive center"
            alt="reader to donor graphic"
            loading="lazy"
          ></img>
          <p>
            The simple progression goes something like this: Once you have
            someone reading your news, the next step is to get them to subscribe
            to the outlet. When they are a subscriber, we might ask them to
            contribute a little more and become a supporting subscriber. Then,
            we might encourage them to donate to the nonprofit. And if they’re
            enthusiastic about being a donor, the next step - after significant
            stewardship - is to ask them to be a major donor.
          </p>
          <p>
            There’s a lot to suggest that formula still works. But it’s smart to
            think beyond it.
          </p>
          <p>
            A fair number of people may read one facet of your reporting — in
            The Tribune’s case, our reporting on the environment or our coverage
            of the Latter-day Saint faith — but they don’t care about, say, city
            news, because they don’t live in our community. So it may not make
            sense for that person to become a subscriber, and we may ask them to
            support us directly as donors.
          </p>
          <p>
            One experiment The Tribune tried, with success, was{" "}
            <a
              href="https://betternews.org/how-the-salt-lake-tribune-developed-mormon-land-to-grow-its-national-audience/"
              target="_blank"
              rel="noreferrer"
            >
              our Mormon Land podcast and newsletter
            </a>
            . We deliver the most balanced and authoritative coverage of The
            Church of Jesus Christ of Latter-day Saints — members of the faith
            read us, in addition to the church-owned Deseret News, to get the
            straight skinny on what church leaders are saying and doing.
          </p>
          <p>
            A lot of people outside of Utah want to read and hear what we’re
            reporting about the church. Those people were unlikely to subscribe
            to The Tribune, so we worked out another way for them to support us,
            by creating{" "}
            <a
              href="https://www.patreon.com/mormonland"
              target="_blank"
              rel="noreferrer"
            >
              a Patreon account
            </a>
            , where people could pay $3, $9 or $15 a month to get enhanced
            access — such as transcripts of the podcast, the chance to submit
            questions to the podcast’s guests and Mormon Land swag.
          </p>
          <p>
            The Tribune also is experimenting with ways to turn our Instagram
            followers into donors. They tend to be younger, a higher percentage
            of women than men, and less interested in committing to a
            subscription — the “cord cutters.” We’re looking to see if those
            readers would contribute to individual stories that they’re
            interested in (e.g., reproductive rights) if we embedded a Venmo QR
            code into the article.
          </p>
          <p>
            Much of our individual giving strategy continues to rely on email.
            One driver of monthly donations is our monthly “Letter From the
            Editor,” which gives subscribers and would-be donors a backstage
            look at The Tribune’s workings. We also have done campaigns
            highlighting our Report For America staffers, for open records costs
            and our end-of-year campaign — where Tribune reporters highlight
            their work in a more personalized way — that aim to spur donations.
          </p>
          <p>
            An important part of increasing engagement — and increasing revenue
            — is to know who you’re talking to, particularly among your own
            readers, subscribers and donors. That means data.
          </p>
          <p>
            That data can include contact information for lapsed print
            subscribers, or print subscribers who haven’t signed on to our
            digital versions (website, e-edition, newsletters, etc.). But in
            collecting such data, be sensitive: Being too insistent about asking
            for this kind of information can turn off a subscriber.
          </p>
          <hr />
          <h2>The “Supporting Subscriber” program</h2>
          <p>
            One of the defining features of The Salt Lake Tribune’s nonprofit
            approach is the “Supporting Subscriber” membership program.
          </p>
          <p>
            Like regular subscribers, Supporting Subscribers pay a monthly or
            annual fee for digital access. And they also elect to contribute an
            additional amount to support The Tribune. (The annual cost to be a
            Supporting Subscriber is $150, compared to the $79.99 annual fee for
            a regular subscription.)
          </p>
          <p>
            The program provides community members with a middle ground between
            donating large one-time gifts and being a subscriber. It also
            enables readers to engage with the organization in a new,
            mission-oriented way, while also maintaining the transactional
            relationship.
          </p>
          <p>
            As of November 2022, The Tribune had nearly 7,000 Supporting
            Subscribers — a quarter of our total number of digital-only
            subscribers.
          </p>
          <p>
            The Supporting Subscriber program is a stepping stone to convert
            subscribers to large donors, by familiarizing them with a more
            intimate, engaged relationship with The Tribune. It also rapidly
            increased reader revenue.
          </p>
          <p>Things to consider:</p>
          <ol className="numbered-list">
            <li>
              <strong>Simplify options for donation amounts</strong> • Right
              now, the page on sltrib.com{" "}
              <a
                href="https://www.sltrib.com/support/"
                target="_blank"
                rel="noreferrer"
              >
                for digital subscribers
              </a>{" "}
              toggles between “basic plans” and “supporting plans,” and offers
              monthly and annual plans for each.
            </li>
            <li>
              <strong>Separate subscriptions from donations</strong> • If you’re
              having people pay a lump sum that covers both the subscription and
              a donation, be prepared with a method for separating deductible
              and nondeductible payments. We have to be clear that donors do not
              get a subscription for donating — and we tell subscribers that
              their subscription fee is not tax-deductible.
            </li>
            <li>
              <strong>Perks work</strong> • Supporting Subscribers receive
              unlimited digital access to sltrib.com, as well as a quarterly
              newsletter just for supporters, a lapel pin designed by our
              beloved political cartoonist Pat Bagley, advance invitations to
              Tribune events, and more. Providing such perks further
              distinguishes the Supporting Subscriber from a standard
              subscriber, and fosters a sense of belonging.
            </li>
            <li>
              <strong>Ask frequently</strong> • Regularly ask subscribers if
              they want to upgrade to becoming a Supporting Subscriber.
            </li>
          </ol>
          <hr />
          <h2>Retaining donors</h2>
          <h3>The donor cultivation cycle:</h3>
          <ul>
            <li>Identification • Identify potential donors/leaders.</li>
            <li>
              Qualification • Determine if the individual or organization is a
              viable prospect.
            </li>
            <li>Cultivation • Move the prospect toward solicitation.</li>
            <li>
              Solicitation • Ask for a gift of a specific amount to a specific
              initiative or general operating.
            </li>
            <li>
              Stewardship • Update, thank and recognize the donor on the use and
              impact of their gift.
            </li>
          </ul>
          <p>(Go back to the top and start again.)</p>
          <p>
            Maintaining existing donor relationships is as critical to building
            donation revenue as converting readers to become new donors.
            Maintaining donors, and increasing their giving, can be summarized
            into three philanthropy practices.
          </p>
          <ul>
            <li>
              <strong>Ongoing Communication</strong> &#8226; Check in and follow
              up with donors regularly, especially after receiving a donation.
              Emails and calls both work. Send “thank you” notes promptly after
              receiving a gift. Process the gift promptly. If you’re asking for
              additional contributions, acknowledge recent donations. Keep
              accurate, organized data to streamline communication. Keeping
              notes on contacts allows the development staff to personalize its
              messaging. Donation data can show patterns and habits, which can
              be analyzed to enable intentional requests (say, to support
              reporting of a favorite topic) and ask to increase the size or
              frequency of their gift based on their ability to pay. Make sure
              any communication with the donor is accurate, and reflects the
              donor’s interests and behavior —they should feel known and valued.
            </li>
            <li>
              <strong>Showing Impact</strong> &#8226; As important as it is to
              show appreciation, it’s also crucial to show donors that their
              donations are making an impact — that the organization needs and
              relies on that money. The Tribune maintains an{" "}
              <a
                href="https://impact.sltrib.com/"
                target="_blank"
                rel="noreferrer"
              >
                Impact Tracker
              </a>
              , viewable by the public, to see where individual stories have
              made a difference. “Impact” can mean anything, from inspiring
              legislation that fixes a problem to a Twitter thread showing how
              people were talking about what we wrote. We also compile a list of
              stories that had impact, and include it in our year-end report to
              donors. Launch donation campaigns around a single financial cause.
              For example, The Tribune rallied donors to raise money to support
              our COVID-19 reporting. Let the donor know, through your language
              and messaging, how much the news outlet relies on them. A simple
              statement like “we could not do this without you” in an email goes
              a long way to underline a donor’s value. Unlike other
              philanthropic groups (say, a symphony or a medical-based
              organization), donors see the product in journalism every day —
              sometimes several times a day. Point out the results of
              high-impact stories, like a change in public policy. Hold events
              so the donors can meet the journalists whose reporting they
              support. Some donors want to see their money go to a specific
              place or cause in the organization. Be honest and transparent
              about where the money goes. Report for America has been very
              successful in helping their partner newsrooms target fundraising
              for their corps members.
            </li>
            <li>
              <strong>Personal Relationships</strong> &#8226; Building a strong
              donor network depends on nurturing real one-on-one relationships.
              Automated emails and follow-ups can help decrease your staff’s
              manual workload and make sure no donor is forgotten. However,
              donors — particularly long-term donors or major gift prospects —
              expect and should receive personal outreach. If there’s something
              wrong in the relationship, donors will stop giving. They may feel
              unappreciated, or don’t know how their money is being used.
              Communication — guided by organized and thorough data — is key to
              find out what’s wrong, and how to fix it and make the relationship
              stronger. If a donor stops giving, don’t badger them. Sometimes
              people move on to another cause.
            </li>
          </ul>
          <hr />
          <h2>How to turn readers to subscribers, and subscribers to donors</h2>
          <h3>Reader analytics</h3>
          <p>
            Study your reader analytics and A/B testing, to find access points
            in your stories and messages that drive conversions — turning
            readers to subscribers and subscribers to donors.
          </p>
          <p>
            Tribune news personnel have become well versed in reader analytics,
            studying sites such as Chartbeat and Power BI to keep tabs on how
            many people are clicking on stories — and how many “conversions,” or
            clicks to start or activate a subscription, a story may generate.
          </p>
          <p>
            Tribune staffers regularly test headlines, offering up three or more
            alternative headlines to see what words or concepts will motivate
            readers to click on a story.
          </p>
          <h3>Subscription and donation requests</h3>
          <p>
            Placement of subscription links, donation links and requests for
            contact information — such as an email or pop-up — can be used to
            drive increased support.
          </p>
          <p>
            In early 2020, The Tribune tested the placement of subscription and
            donation links on different parts of the sltrib.com website,
            tracking how placement of those links translated into reader
            support. Among the findings:
          </p>
          <ul>
            <li>
              Requests to subscribe were more successful, by a rate of 5 to 1 or
              better, than requests to donate.
            </li>
            <li>
              A pop-up when a reader was leaving the subscribe page, offering a
              discounted deal, saw an incredible jump in conversions. A similar
              pop-up when exiting the donation page quadrupled the donation rate
              on that page.
            </li>
            <li>
              Differentiating subscriptions by coverage area brought out varying
              results — appeals to Utah Jazz fans were less likely to convert
              readers, but opinion-page readers using a desktop were more likely
              to subscribe.
            </li>
            <li>
              Personal stories matter. We did tests for donation requests, from
              people who subscribe and people who don’t, around our free content
              — including COVID-19 information and stories about an earthquake
              that hit Utah in March 2020 — and the conversion rates were solid.
              (One letter from the editor in March 2020, used as a pop-up, had
              double the conversion rate.)
            </li>
            <li>
              We put an image of a photographer, working during the COVID-19
              pandemic, on one of the donation request pages — and it did 16
              times better than the same message on a request to subscribe. In
              that donation request, an ask of $25 did better than larger or
              smaller amounts.
            </li>
            <li>
              Messages that talk about community — how a nonprofit Tribune is a
              community resource, and “owned” by the community — were more
              successful than messages about journalism or democracy.
            </li>
          </ul>
          <br></br>
          <h3>Newsletters</h3>
          <p>
            You can’t just put up a lemonade stand and expect everyone to get in
            line. You have to go where the people are. Often, that place is
            their inbox.
          </p>
          <p>
            Newsletters help us understand what readers are interested in
            reading, and are a solid entry point for deeper engagement (which,
            ultimately, means more money for us).
          </p>
          <p>
            Get ready for a lot of experimentation, and a lot of trial and
            error. Some ideas for newsletters will do very well for you — in The
            Tribune’s case, the Mormon Land newsletter, talking about all things
            covering The Church of Jesus Christ of Latter-day Saints, took off
            immediately. Some, like election coverage or sports teams, will see
            readership rise in some parts of the year, then fall in the
            off-season. You’ll find out what works for you on the local level.
          </p>
          <h3>Subscription and donation</h3>
          <p>
            Seek out chances to increase a subscription’s value for limited time
            periods, through partnerships with other organizations to provide an
            additional product or service as a “bonus.” This can mean exclusive
            content, access to events, or merchandise (“swag”) to influence
            readers and subscribers to kick their support up to the next level.
          </p>
          <hr />
          <h2>Audience Data</h2>
          <p>
            To gain supporters, you have to engage more people in your audience.
            To do that, you have to get to know the different groups in your
            audience. That requires data.
          </p>
          <p>
            The point of collecting audience data is to see who is paying for
            subscriptions, who is donating to the cause, who is doing both, and
            who is doing neither. By knowing which readers are subscribing, you
            can eventually ask them to become donors.
          </p>
          <p>
            We would like to say we have gotten more sophisticated, two years
            in, in how we collect audience data. Unfortunately, that’s one area
            where progress has been slow.
          </p>
          <p>
            When we started as a nonprofit, we started to rely on several
            systems to manage our audience data:
          </p>
          <ul>
            <li>
              For payment processing, we used{" "}
              <a href="https://piano.io/" target="_blank" rel="noreferrer">
                Piano
              </a>
              , both for subscription payments and donations attached to our
              Supporting Subcribers’ monthly subscriptions.{" "}
              <a
                href="https://piano.io/product/dmp/"
                target="_blank"
                rel="noreferrer"
              >
                DMP
              </a>
              , a Piano product, gave us more targeted information about our
              readers, what they were looking at, and what they were willing to
              pay to see.
            </li>
            <li>
              For account management, we used Salesforce. We routed our
              donations information through Salesforce, which logged pledged
              donations, and generated gift receipts and thank-you emails. We
              also used Salesforce to track grant applications and major gifts.
            </li>
            <li>
              In the early going, we used iWave to wealth-screen all donors in
              certain zip codes who gave over $100 — but we stopped using iWave
              because it costs money. Once we have contacted the prospects that
              we gathered from that screening, we plan to perform another screen
              of donors.
            </li>
            <li>
              We used MailChimp to start and track large-scale communications
              and campaigns, using donor data from Salesforce to target email
              campaigns. (In 2023, we’re moving to a new company, Iterable.)
            </li>
          </ul>
          <br></br>
          <br></br>
          <br></br>
          <div>
            <p>
              <strong>For further reading:</strong>
            </p>
            <p>
              •{" "}
              <a
                href="https://www.philanthropy.com/package/how-to-make-the-most-of-your-meetings-with-big-donors"
                target="_blank"
                rel="noreferrer"
              >
                ”How to Make the Most of Your Meetings With Big Donors”
              </a>
              , The Chronicle of Philanthropy. (Or just bookmark{" "}
              <a
                href="https://www.philanthropy.com/"
                target="_blank"
                rel="noreferrer"
              >
                the whole site.
              </a>
              )
            </p>
            <p>
              •{" "}
              <a
                href="https://www.americanpressinstitute.org/category/reader-revenue/"
                target="_blank"
                rel="noreferrer"
              >
                Reader Revenue Toolkit
              </a>
              , American Press Institute, 2022
            </p>
          </div>
          <div className="next-section">
            <h4>
              Next Section: <Link to="/collaborations">Collaborations</Link>
            </h4>
          </div>
          <hr />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default AudienceRevenue;
