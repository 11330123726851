import React from "react";
import Calculator from "../../components/Calculator/Calculator";
import DocumentMeta from "react-document-meta";
import { Link } from "react-router-dom";
import "./NonprofitBusiness.scss";

const NonprofitBusiness = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Will it Work for You?",
    description:
      "The Salt Lake Tribune presents how we make revenue as a nonprofit. Find out if our methods work for your news organization.",
    canonical: "https://playbook.sltrib.com/will-it-work-for-you",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "nonprofit, nonprofit playbook, the salt lake tribune, nonprofit newsroom,",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="nonprofit-business">
        <div className="nonprofit-business-content">
          <h1>Will It Work for You?</h1>
          <figure>
            <img
              src="/nonprofit-playbook-piechart.jpg"
              className="responsive center"
              alt="Salt Lake Tribune Buidling"
              loading="lazy"
              style={{ "margin-bottom": "10px" }}
            ></img>
            <figcaption
              className="responsive center"
              style={{ "margin-bottom": "25px", fontStyle: "italic" }}
            >
              The Salt Lake Tribune revenue breakdown, 2022.
            </figcaption>
          </figure>
          <p>
            When The Salt Lake Tribune decided to change its way of doing
            business, the company’s leadership explored different potential
            business models to see what would work.
          </p>
          <p>
            The models broke down into two categories: Turning the paper itself
            into a nonprofit charitable organization, or creating a nonprofit
            foundation to financially support a for-profit newspaper.
          </p>
          <p>
            The first category — turning the paper into a nonprofit — had never
            been done before with a metropolitan daily, or “legacy,” newspaper
            like The Tribune. There were successful examples of nonprofit news
            organizations, but most of them were created as digital start-ups (
            <a
              href="https://www.texastribune.org/"
              target="_blank"
              rel="noreferrer"
            >
              The Texas Tribune
            </a>{" "}
            and{" "}
            <a
              href="https://www.propublica.org/"
              target="_blank"
              rel="noreferrer"
            >
              ProPublica
            </a>{" "}
            are prime examples) or hyper-local or issue-specific digital news
            sites.
          </p>
          <p>
            There were more examples of the second category: A paper remaining
            for-profit (as either an LLC or modified to B-Corp), but owned or
            supported by a nonprofit. (We’ll talk about those at the end of this
            section.)
          </p>
          <hr />
          <h2>Nonprofit News Organization, No Meter</h2>
          <p>
            This is sometimes called the “NPR model,” because it’s often used by
            public radio stations: The consumer doesn’t have to pay a dime (it’s
            available free over the airwaves, after all), but people may feel
            compelled to donate money because they agree with the outlet’s
            mission.
          </p>
          <p>
            It’s also more commonly used by outlets that are starting fresh,
            particularly digital-forward organizations, because of their minimal
            operating and printing expenses. (ProPublica and The Texas Tribune
            are prime examples.) It’s a tougher road for legacy newspapers,
            because of their existing overhead — but, over time, it can be done.
          </p>
          <h3>Free Access to News</h3>
          <ul>
            <li>No subscription revenue.</li>
            <li>Relies on grants, corporate sponsorships, and donations.</li>
            <li>
              Additional revenue from events or advertising, which may be
              considered Unrelated Business Income and thus taxed.
            </li>
          </ul>
          <hr></hr>
          <h2>Hybrid Supporting-Subscriber Model</h2>
          <p>
            The hybrid model receives grants from a local nonprofit foundation
            (similar to{" "}
            <a
              href="https://www.lenfestinstitute.org/"
              target="_blank"
              rel="noreferrer"
            >
              Lenfest
            </a>{" "}
            in Philadelphia and{" "}
            <a href="https://www.poynter.org/" target="_blank" rel="noreferrer">
              Poynter
            </a>{" "}
            in Florida) — while the news outlet becomes a nonprofit organization
            itself. This model includes revenue from donations and from
            subscriptions.
          </p>
          <h3>Nonprofit Foundation</h3>
          <ul>
            <li>Build endowment through fundraising.</li>
            <li>
              Receive public and private donations that indirectly support the
              newspaper.
            </li>
          </ul>
          <p>
            The foundation makes tax-deductible grants to the newspaper to
            support local journalism. Other revenue sources include:
          </p>
          <ul>
            <li style={{ textIndent: "25px" }}>
              Gifts from individuals, one-time and recurring.
            </li>
            <li style={{ textIndent: "25px" }}>Supporting subscribers.</li>
            <li style={{ textIndent: "25px" }}>Subscriptions.</li>
            <li style={{ textIndent: "25px" }}>
              Grants from other grant-making institutions: family foundations,
              corporations, other organizations.
            </li>
          </ul>
          <br></br>
          <h3>Nonprofit Newspaper (with Meter)</h3>
          <ul>
            <li>
              Receives donations from individuals, corporations and foundations
            </li>
            <li>Significant revenue from subscription.</li>
            <li>
              Additional revenue from events, advertising, corporate
              underwriting, or other unrelated and therefore potentially taxable
              business income.
            </li>
          </ul>
          <br></br>
          <h3>From hybrid to fully nonprofit</h3>
          <p>
            After a short transition period, The Tribune itself was transformed
            from a for-profit to a 501(c)(3) charitable organization. It
            retained the meter and thus subscription revenue, and added a
            “Supporting Subscriber” membership and a major gifts program.
          </p>
          <p>
            Becoming nonprofit was not the end of the transformation process,
            but the beginning, and it continues. Exploring a sustainable
            business model to accompany the change brought many more questions:
          </p>
          <ul>
            <li>Can we move to a membership, or voluntary support, model? </li>
            <li>Should we provide the digital news product for free?</li>
            <li>
              What improvements need to be made to our digital products to
              increase subscriptions?
            </li>
            <li>
              How can we optimize the change in tax status to enhance our
              community engagement?
            </li>
            <li>
              What new partnerships might be available to us as a nonprofit?
            </li>
            <li>
              How can we take editorial positions while being mindful of IRS
              rules?
            </li>
            <li>
              How can we make portions or all of our news free to underserved
              populations? On what platforms?
            </li>
            <li>
              How can we ensure the public that donors will not influence the
              stories we tell or how we tell them?
            </li>
            <li>
              How can we convince local foundations and corporations to support
              our journalism while ensuring no influence over reporting?
            </li>
            <li>
              How will we measure the impact of our reporting within a new
              business model?
            </li>
            <li>
              How can we create a long-term endowment to support local
              journalism in Utah?
            </li>
          </ul>
          <p>
            Underlying all of these questions is the big one: What does our
            community — now our owners — want?
          </p>
          <hr></hr>
          <h2>Keeping the meter</h2>
          <p>
            When The Tribune started as a nonprofit, it had something the
            digital startups didn’t when they launched: existing personnel and
            operations. Fundraising was just getting started.
          </p>
          <p>
            The Tribune wasn’t financially prepared to give away the news it
            produced under the free-access model (the way public radio does). So
            The Tribune operates as a social enterprise, a nonprofit
            organization that provides a product to generate revenue, in order
            to fulfill its social mission. As a social enterprise, market-based
            revenue strategies (like advertising and subscriptions) can pair
            with philanthropic sources of funding (like donations and grants),
            and lend greater sustainability to the operation.
          </p>
          <p>
            The hybrid model maintains subscriptions for most reporting, but for
            the first time asks people to contribute to the newspaper as well as
            to subscribe.
          </p>
          <p>
            This type of membership model is common in the performing arts. A
            nonprofit group, such as the ballet or the symphony, sells tickets
            to performances (which are not tax-deductible), and takes in
            charitable donations that support the group’s artistic production. A
            major benefactor or private foundation may give to the arts group,
            and see their name affixed to the performance hall. Major donors get
            listed in the program, which also carries paid advertising. The arts
            group provides free educational programming to schools, as part of
            its community mission.
          </p>
          <p>
            The Tribune sets its meter at 3 free stories per month before the
            paywall goes up; some stories, like exclusives, are behind the
            paywall no matter how many stories someone has read first. A
            percentage of stories — an average of 33 per month, or 7.5% — remain
            free, or “in front of the paywall”; including stories aimed at
            underrepresented communities, or that are considered vital in an
            emergency situation, such as vaccine information during the COVID-19
            pandemic. Reporting is free on other platforms, like YouTube and
            Instagram.
          </p>
          <h3>Two factors led to the decision to keep the meter:</h3>
          <ol className="numbered-list">
            <li>
              Print and digital revenue brought in enough money that removing it
              would significantly increase the organization’s dependence on
              donations right from the start. The organization had pre-existing
              operating costs that could only be met with subscription revenue.
              Keeping the meter aligned with a first priority of achieving
              financial stability, without compromising the mission.
            </li>
            <li>
              As a legacy newspaper, radical change — like dropping a primary
              source of revenue — is more difficult. Becoming a nonprofit was a
              huge step to take towards a new model and new relationship with
              the public. Preserving the prior, transactional, news-to-reader
              relationship maintains a thread of familiarity that supports the
              legacy and history of The Salt Lake Tribune.
            </li>
            <li>
              As we progress, we keep testing the model. Reporting in the public
              interest — such as election-night results or COVID information —
              is free. So is coverage to an underserved community (example:
              Spanish translations of stories that affect the Latino community).
              Finally, about a year into our journey we started sharing daily
              reporting with partner news organizations in Utah so their
              communities were also informed on key topics, principally in the
              state government and environment arenas.
            </li>
          </ol>
          <hr></hr>
          <h2>Revenue for a nonprofit: The one-third rule</h2>
          <p>
            In revenue terms, being a nonprofit is all about how — and from
            where — you get the money to run the company.
          </p>
          <p>
            The IRS has a rule — called the one-third rule — that can be boiled
            down to this: To call oneself a nonprofit, an organization cannot
            collect more than 33.3%, a third, of its revenue from advertising.
          </p>
          <p>
            A key part of this rule is defining what is considered
            “advertising.”
          </p>
          <p>
            If a company pays to put out a message that is selling something,
            that’s advertising. “Come to Bob’s House of Goods and Services, and
            get 50% off a 2023 widget — just $10 for a new widget” is
            advertising.
          </p>
          <p>
            But if the same company pays to put out a message that doesn’t
            mention a particular deal — if it’s more general, like “Brought to
            you by Bob’s House of Goods and Services, proud supporter of
            independent journalism” — that’s not advertising. That's considered
            “underwriting,” and it doesn’t count against the ⅓ limit. (You hear
            this sort of thing on public radio all the time.)
          </p>
          <p>
            The difference between advertising and underwriting can get tricky.
            Generally, it is up to the corporation to decide how to characterize
            their contribution or payment for tax purposes. Public radio has
            extensive experience in this area, and{" "}
            <a
              href="https://www.npr.org/sections/publiceditor/2015/03/11/392355447/nprs-underwriting-guidelines-part-one"
              target="_blank"
              rel="noreferrer"
            >
              using their guidelines
            </a>{" "}
            is a good call.
          </p>
          <p>
            Another important aspect of the one-third rule: The IRS gives a
            nonprofit five years, from the time their nonprofit status is
            official, to get the level of their advertising revenue under the
            33.3% mark. That gives the organization time to build up the other
            sources of revenue — grants, donations, partnerships — that are
            considered “public support.”
          </p>
          <p>
            (Word of advice: Don’t take what we’re saying here as gospel.
            Consult with your own lawyers and accountants.)
          </p>
          <div>
            <Calculator />
          </div>
          <hr></hr>
          <h2>Accounting for nonprofit revenue streams</h2>
          <p>
            In the hybrid supporting-subscriber model, it’s crucial to keep
            track of where money is coming from, and how it’s used, so as to
            maintain accurate accounting for organization and donor taxes. As a
            nonprofit, tax-exempt revenue will be reported to the IRS using form
            990, while taxable revenue (Unrelated Business Income) is filed
            through form 990-T.
          </p>
          <p>
            <strong>Note: </strong>This is a general overview, and not legal or
            accounting advice. Talk to an accountant with experience in
            nonprofits about your situation.
          </p>
          <p>Tax-exempt revenue:</p>
          <ul>
            <li style={{ textIndent: "25px" }}>
              Donations from individuals, either one-time or recurring.
            </li>
            <li style={{ textIndent: "25px" }}>
              Recurring donations attached to a monthly subscription.
            </li>
            <li style={{ textIndent: "25px" }}>
              Private foundation and corporate grants.
            </li>
            <li style={{ textIndent: "25px" }}>
              Program-related income or investments.
            </li>
            <li style={{ textIndent: "25px" }}>
              Corporate underwriting (without logo or direct sales pitch).
            </li>
            <li style={{ textIndent: "25px" }}>Revenue from events. </li>
          </ul>
          <br></br>
          <p>Tax-exempt but not deductible:</p>
          <ul style={{ marginTop: "-15px" }}>
            <li>Subscriptions.</li>
          </ul>
          <br></br>
          <br></br>
          <p>Taxable revenue:</p>
          <ul style={{ marginTop: "-15px" }}>
            <li>
              Advertising (includes a logo and a sales pitch for a specific good
              or service; this is different from underwriting).{" "}
            </li>
            <li>
              Other Unrelated Business Income, such as non-educational events.
            </li>
          </ul>
          <hr></hr>
          <h2>Strategies for success:</h2>
          <ul>
            <li>
              Invest in the organization’s capacity to handle increased
              accounting needs by hiring, contracting or consulting with tax and
              accounting experts.
            </li>
            <li>
              Consider robust CRM software to most effectively track donations,
              subscriptions and any other revenue separately.
            </li>
            <li>
              Be aware that some grants and gifts are “restricted,” requiring
              additional accounting.
            </li>
          </ul>
          <br></br>
          <h3>Transparency:</h3>{" "}
          <p>
            As a nonprofit, the organization is held to greater financial
            transparency requirements. Required reporting includes the
            following:
          </p>
          <ol className="numbered-list">
            <li>
              Form 990: The IRS Form 990 is a document, filed annually,
              detailing the tax-exempt financial behavior of the organization,
              and should be made publicly accessible. Major donors are listed on
              the form, as well as the compensation of the highest paid
              employees. The IRS provides resources & tools for filing form 990.
              It is best practice to place the 990 on the organization’s
              website.
            </li>
            <li>
              Major Donors: Many nonprofits choose to list all their donors, and
              some even publish the amount of their donation. This is above and
              beyond the disclosures required by the 990. As a condition of
              membership, the Institute for Nonprofit News requires disclosure
              of donors that give more than $5,000 annually, either by
              publishing these donors on the organization’s website or through
              posting a direct link to the 990 filing that outlines these.
              {/* donors. <a 
                href="https://www.sltrib.com/first-amendment-society/"
                target="_blank"
                rel="noreferrer"
              >(Here is The Tribune’s list of major donors.)
              </a> */}
            </li>
            <li>
              Donor Tax Information: As a matter of course, nonprofits should
              send a thank-you immediately to donors, no matter the amount of
              their gift. The 501(c)(3) nonprofit is also obligated to provide a
              receipt to donors who have contributed cumulative gifts over $250
              per year. An organization may choose, though it is not required,
              to send all donors a tax letter to ensure good faith and protect
              against legal liability. And saying thanks is always good
              practice.
            </li>
          </ol>
          <p>
            For more information regarding public disclosure, access this
            additional resource list compiled by the{" "}
            <a
              href="https://www.councilofnonprofits.org/"
              target="_blank"
              rel="noreferrer"
            >
              Institute for Nonprofit News National Council of Nonprofits
            </a>
            . The
            <a
              href="https://inn.org/resources/"
              target="_blank"
              rel="noreferrer"
            >
              Institute for Nonprofit News
            </a>{" "}
            is another excellent resource on gift policies and disclosures
            specific to news.
          </p>
          <hr></hr>
          <h2> Revenue diversification</h2>
          <p>
            It’s important to create and maintain a diverse number of revenue
            sources.
          </p>
          <p>
            Philanthropy is vital, but usually not enough to sustain a large
            organization. Subscriptions, events, sponsorships and ads can all
            play a role, alongside grants and gifts.
          </p>
          <p>
            And philanthropic revenue should, itself, come from diverse sources.
            Public charities — 501(c)(3) nonprofits — are expected to have both
            diverse control through a sufficiently large and multi-perspective
            board, and demonstrate diverse funding. A nonprofit should show it
            is supported by the broad public, and not be dependent on one donor
            or one foundation. No single voice should control the board, either.
          </p>
          <p>
            To ensure a broad base of donors, the IRS has another one-third
            rule, which requires nonprofits report at least one-third (33.3%) of
            donations are made by small donors who give less than 2% of the
            nonprofit’s overall revenue. Program income (which includes
            subscriber revenue) can also count toward the one-third total. The
            organization has five years to achieve that one-third level, and the
            “test” is based on a five-year period (the current year and the
            previous four).
          </p>
          <p>
            Collecting from many small donors demonstrates the nonprofit is
            supported by the community. Not only is this good fiscal policy, but
            it dilutes any one person or group from having (or appearing to
            have) too much influence. For news organizations, who are used to
            keeping a firewall between revenue sources and editorial content,
            this kind of diversification is critical, both financially and
            ethically. Supporting Subscribers - who pay for a subscription and
            also donate - help The Tribune eclipse this ⅓ baseline.
          </p>
          <p>
            (​​The Texas Tribune, a digital-only nonprofit, explains its
            approach in{" "}
            <a
              href="https://www.texastribune.org/about/texas-tribune-strategic-plan/"
              target="_blank"
              rel="noreferrer"
            >
              its strategic plan
            </a>
            .)
          </p>
          <hr></hr>
          <h2>Alternate Business Models</h2>
          <p>
            There are innovative business models other than the nonprofit route
            The Tribune has taken. They often combine nonprofit methods with
            old-school capitalism.
          </p>
          <h3>Nonprofit Foundation + For-Profit Newspaper</h3>
          <p>
            Creating a side-by-side foundation, or working with an existing
            local foundation, to support your paper is an option for some. The
            foundation is able to accept donations and raise funds through
            private philanthropy. The foundation supports the news organization
            through contracts and grants for its service. This model
            incorporates philanthropy, indirectly, into the business model of
            the news organization without changing the organization’s tax
            status.
          </p>
          <h4>Examples: </h4>
          <ol className="numbered-list">
            <li>
              Philadelphia Media Group (Philadelphia Inquirer) +{" "}
              <a
                href="https://www.lenfestinstitute.org/"
                target="_blank"
                rel="noreferrer"
              >
                The Lenfest Institute
              </a>
              .
            </li>
            <li>
              <a
                href="https://www.tampabay.com/"
                target="_blank"
                rel="noreferrer"
              >
                Tampa Bay Times
              </a>{" "}
              (owned by Times Publishing Company) +{" "}
              <a
                href="https://www.poynter.org/"
                target="_blank"
                rel="noreferrer"
              >
                Poynter Institute
              </a>
              .
            </li>
            <li>Seattle Times (and other) lab initiatives.</li>
          </ol>
          <h4>Nonprofit Foundation: </h4>
          <ul>
            <li>
              Seeks a relationship with existing foundations, including funds
              held at community or place-based foundations.
            </li>
            <li>
              The foundation can raise funds from private philanthropic groups.
            </li>
          </ul>
          <h4>The Foundation: </h4>
          <ol className="numbered-list">
            <li>
              Makes grants to and contracts with local news organizations to
              support local journalism.
            </li>
            <li>
              Accepts donations and gifts that indirectly support the newspaper
              through contracts for service (such as reporting).
            </li>
          </ol>
          <h4>LLC Newspaper or B-Corp Newspaper</h4>
          <ul>
            <li>Contracts with or receives grants from foundation.</li>
            <li>
              Direct revenue streams include subscriptions, sales, advertising,
              private investments, ancillary businesses, etc.
            </li>
          </ul>
          <h4>Shared attributes of successful newspapers:</h4>
          <ul>
            <li>
              The news organization is financially viable, or has strong revenue
              from subscriptions or private capital.
            </li>
            <li>
              Ownership structure does not lend itself to easily becoming
              nonprofit, or the owner (or owners) wishes to maintain
              oversight/authority/influence.
            </li>
            <li>
              Local philanthropic support is available through a community
              foundation or other foundation
            </li>
          </ul>
          <div>
            <br></br>
            <br></br>
            <br></br>
            <p>
              <strong>For further reading:</strong>
            </p>
            <p>
              •{" "}
              <a
                href="https://www.irs.gov/pub/irs-pdf/p557.pdf"
                target="_blank"
                rel="noreferrer"
              >
                “Tax-Exempt Status for Your Organization”
              </a>
              , Internal Revenue Service, publication 557, revised January 2023.
            </p>
            <p>
              •{" "}
              <a
                href="https://www.irs.gov/pub/irs-pdf/p4221pc.pdf"
                target="_blank"
                rel="noreferrer"
              >
                “Compliance Guide for 501(c)(3) Public Charities”
              </a>{" "}
              , Internal Revenue Service.
            </p>
          </div>
          <div className="next-section">
            <h4>
              Next Section:{" "}
              <Link to="/making-the-transition">Transitioning</Link>
            </h4>
          </div>
          <hr />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default NonprofitBusiness;
