import React from "react";
import { Link } from "react-router-dom";
import Video from "../../components/Video/Video";
import DocumentMeta from "react-document-meta";
import "./Introduction.scss";

const Introduction = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Introduction",
    description:
      "Learn more about The Salt Lake Tribune and how its operated as a nonprofit and how it continues to serve Utahns news.",
    canonical: "https://playbook.sltrib.com/introduction",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "nonprofit, nonprofit playbook, the salt lake tribune",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="introduction">
        <div className="introduction-content">
          <h1>Introduction</h1>
          <img
            src="/sltrib-building.jpg"
            className="responsive center"
            alt="Salt Lake Tribune Buidling"
            loading="lazy"
          ></img>
          <h2>Who and What This Playbook is For</h2>
          <p>
            Since November 2019, The Salt Lake Tribune has operated as a
            nonprofit — an experiment that has ensured an institution more than
            150 years old continues to serve Utahns. The decision to become a
            nonprofit was prompted by our financial standing — and the grim
            outlook most in the news industry, particularly{" "}
            <a
              href="https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/"
              target="_blank"
              rel="noreferrer"
            >
              local newspapers
            </a>
            , have faced.
          </p>
          <p>
            There is no one-size-fits-all solution, but what you’ll find here is
            a look at where The Tribune has made progress since becoming a
            nonprofit and where it hasn’t. We first published this playbook in
            2020 when we had plans but few results. In this update, we offer
            strategic and tactical learnings.
          </p>
          <p>
            What we didn’t expect — and what we have embraced — is how becoming
            a nonprofit has changed The Salt Lake Tribune's journalism.
          </p>
          <p>
            Not everything on our roadmap will work for every news organization.
            Solutions must be rooted locally. What makes The Tribune a unicorn
            is not our status, that we continue to build support from our local
            readers and donors by sharing essential news and information. For
            those news outlets that are interested in the nonprofit route, The
            Tribune’s experience may help you dodge some of the potholes,
            facilitating what we hope is a successful transition to
            sustainability.
          </p>
          <hr />
          <h2>The Salt Lake Tribune</h2>
          <p>
            The Salt Lake Tribune was the first legacy news organization in the
            United States to receive the Internal Revenue Service’s approval to
            become a public charity under section 501(c)(3) of the tax code — to
            become, in simpler terms, a nonprofit.
          </p>
          <p>
            Since its founding in 1871, The Tribune has served as Utah’s
            independent voice for news and information. For most of that run,
            the paper was owned by one family. In 1997, the paper was sold to
            Tele-Communications Inc. (TCI) — which, a short time later, merged
            with AT&T Corp. In January 2001, AT&T sold The Tribune to
            Denver-based Media News Group, which in 2010 sold the paper to Alden
            Global Capital, a New York-based hedge fund.
          </p>
          <p>
            In 2014, Alden renegotiated the decades-long joint operating
            agreement with Salt Lake City’s other daily newspaper, the Deseret
            News, putting The Tribune at a financial disadvantage. That change
            led to layoffs, a lawsuit, and a U.S. Department of Justice
            investigation.
          </p>
          <p>
            After years of negotiations, Utah businessman Paul Huntsman — whose
            family, led by industrialist John Huntsman Sr., has a long
            reputation for philanthropy — bought The Tribune in 2016, viewing it
            as a community asset.
          </p>
          <p>
            It was an asset, though, that was spending more than it took in. It
            quickly became apparent to Huntsman that the traditional newspaper
            business model – relying on advertising and subscription revenue,
            along with ancillary enterprises — was not enough to ensure the
            paper’s long-term viability, in part due to the obligations of the
            joint operating agreement. The Tribune was about to embark on a
            seismic change.
          </p>

          <img
            src="/tribune-staff.jpg"
            className="responsive center"
            alt="tribune-staff"
            loading="lazy"
          ></img>
          <hr />
          <h2>What’s changed</h2>
          <p>
            What changed at The Salt Lake Tribune when we became a nonprofit?
          </p>
          <p>
            The central mission — to share news with people who care about Utah
            — remains the same as before. Because we are now owned by the
            community, though we approach our work with a public service lens.
          </p>
          <p>
            A cynical journalist might argue that news organizations always
            follow the whims of the people who own them. Many journalists have
            danced around the sacred cows — sometimes economic, often political
            — of the owner, whether that’s a single person or a corporation.
          </p>
          <p>
            At The Tribune, where there are thousands of donors and tens of
            thousands of subscribers, the single person is harder to single out.
            Our focus is changing. We listen more to our community before we
            start our reporting. We regularly ask who is being affected — which
            leads to stories that are more about the outcome than the process.
            We are practicing{" "}
            <a
              href="https://www.solutionsjournalism.org/"
              target="_blank"
              rel="noreferrer"
            >
              solutions-oriented journalism
            </a>
            .
          </p>
          <p>
            Where we share our reporting is also changing. We share as much
            journalism with our{" "}
            <a
              href="https://www.instagram.com/sltrib/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>{" "}
            followers as we do with our print subscribers. We invest in{" "}
            <a
              href="https://www.youtube.com/@sltrib"
              target="_blank"
              rel="noreferrer"
            >
              YouTube
            </a>
            . We try to meet the people where they are, both because we can’t
            afford to wait for them to come to us and because everyone deserves
            access to credible local news.
          </p>
          <p>
            One example of how our journalism is changing is the Great Salt Lake
            Collaborative, in which 19 news outlets — including The Tribune —
            banded together to report on the problems facing the shrinking Great
            Salt Lake, and to write about solutions to save the lake. It’s been
            working. As one water reporter, who has been covering the lake for a
            decade, said, “This is the most engaged I’ve seen members of the
            public on the matter. I don't know if it's the collaborative,
            lawmakers speaking up about it or something else, but it's nice to
            see Utahns and the rest of the nation paying attention.”
          </p>
          <p>
            It’s difficult to imagine the old Tribune embarking on such a
            cooperative effort. But working as a community-minded nonprofit, it
            feels natural.
          </p>
          <p>
            With our focus on community, the illusion of objectivity that
            separated the creators of journalism from the readers/viewers of
            that journalism is gone. There’s no longer “us” and “them;” we’re
            all together in seeking better for our communities.
          </p>

          <h2>The first two years</h2>
          <p>
            Here’s what we’ve done to carry out that mission in our first two
            years as a nonprofit:
          </p>
          <ul>
            <li>
              At the start of 2021, we decreased expenses by cutting our print
              edition from a daily to a once-a-week Sunday publication (we’re
              now twice weekly). Our print subscribers are loyal and yet we
              recognize the number of people who pay for the paper to be
              delivered will continue to shrink.
            </li>
            <li>
              Our joint operating agreement with our rival paper, The Deseret
              News, ended after 68 years — we no longer have shared advertising
              and circulation departments. We built a new advertising team,
              called{" "}
              <a
                href="https://tribconnect.com/"
                target="_blank"
                rel="noreferrer"
              >
                TribConnect
              </a>
              , from the ground up.
            </li>
            <li>
              As a nonprofit, we have been able to draw from revenue streams we
              couldn’t use before — like donations from the public and grants
              from foundations and other organizations. (We’ll dig into that
              more later in the playbook.)
            </li>
            <li>
              We learned the vital importance of turning readers into
              subscribers — and subscribers into donors. (We’ll discuss that
              journey later.)
            </li>
            <li>
              We also learned how much sharing our story matters — and that it’s
              not enough to say “we’re a nonprofit now,” but that we have to
              share with our community how what they get from The Tribune is
              different. (More on that later, too.)
            </li>
            <li>
              We have made or reconnected with many friends, other nonprofits
              that want to team up on projects. We have collaborated with PBS’s
              “Frontline”{" "}
              <a
                href="https://www.pbs.org/wgbh/frontline/documentary/shots-fired/"
                target="_blank"
                rel="noreferrer"
              >
                on a documentary on police shootings
              </a>
              , public radio station KUER and American Public Media on a{" "}
              <a
                href="https://www.sltrib.com/sentaway/"
                target="_blank"
                rel="noreferrer"
              >
                podcast about the “troubled teen” industry
              </a>
              , Utah colleges and universities to{" "}
              <a
                href="https://amplifyutah.org/"
                target="_blank"
                rel="noreferrer"
              >
                highlight student journalism
              </a>
              , and news outlets statewide{" "}
              <a
                href="https://greatsaltlakenews.org/"
                target="_blank"
                rel="noreferrer"
              >
                to report on the plight of the Great Salt Lake
              </a>{" "}
              with a focus on solutions.
            </li>
            <li>
              We added reporters, audience producers and a video journalist
              while reaching sustainability, which for us means we’re no longer
              running in the red. While we haven’t yet reached endowment status,
              every day we are learning what it means to be a fiscally
              responsible news outlet — even as a nonprofit.
            </li>
          </ul>
          <hr />
          <Video />
          <hr />
          <h3>
            Timeline: Ownership of The Salt Lake Tribune over the last 25 years.
          </h3>
          <div class="timeline">
            <div class="outer">
              <div class="card">
                <div class="info">
                  <h3 class="title">1997</h3>
                  <p>
                    Kearns-Tribune Inc., the family-owned parent company of The
                    Salt Lake Tribune for nearly a century, is acquired by
                    Tele-Communications Inc. (TCI) — which ultimately becomes
                    part of Comcast. The sale comes with the understanding that
                    the McCarthey family would be able to buy The Tribune back
                    in five years.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">1999</h3>
                  <p>
                    TCI merges with AT&T, which starts looking to sell The
                    Tribune.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2000</h3>
                  <p>
                    AT&T sells The Tribune to Denver-based MediaNews Group,
                    headed by Dean Singleton. The McCartheys blame The Church of
                    Jesus Christ of Latter-day Saints (which owns the Deseret
                    News, Salt Lake City’s other daily newspaper and The
                    Tribune’s partner in a joint operating agreement) for
                    thwarting their plans to buy back the paper, and sue to
                    block the sale, unsuccessfully.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2010</h3>
                  <p>
                    Singleton’s MediaNews Group, having gone into debt buying up
                    other papers, emerges from bankruptcy, with its lenders
                    taking majority ownership. Those lenders are led by Alden
                    Global Capital, a hedge fund that develops a reputation as a
                    “vulture capitalist,” slashing budgets and stripping papers
                    of their assets.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2010-2015</h3>
                  <p>
                    The Tribune endures Alden’s draconian cost-cutting tactics,
                    including three waves of layoffs and a deal between Alden
                    and the Deseret News to change the revenue split from the
                    joint operating agreement in the D-News’s favor. Ironically,
                    the JOA keeps Alden from making deeper cuts, because the
                    agreement — and a lawsuit brought by former Tribune staffers
                    — brings scrutiny from the U.S. Department of Justice.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2016</h3>
                  <p>
                    Huntsman Family Investments — backed by industrialist Jon
                    Huntsman Sr. and managed by his son, Paul Huntsman — buys
                    The Tribune from Alden.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2017</h3>
                  <p>
                    The Tribune wins the Pulitzer Prize for local reporting, for
                    reporting (begun during the Alden era) on how sexual assault
                    victims at Brigham Young University and Utah State
                    University were mistreated by the system that was supposed
                    to protect them. The Mormon Land podcast, a weekly look at
                    the Latter-day Saint faith, launches.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2018</h3>
                  <p>
                    Paul Huntsman announces a fourth round of layoffs, cutting
                    34 employees out of a staff of 90.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2019</h3>
                  <p>
                    The Salt Lake Tribune is given approval from the Internal
                    Revenue Service to become a 501(c)(3) nonprofit — the first
                    legacy newspaper in America to receive the designation. Paul
                    Huntsman relinquishes ownership of the paper to a community
                    model, and takes the role of chairman of the nonprofit’s
                    board of directors.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2020</h3>
                  <p>
                    Because of the COVID-19 pandemic, and damage from an
                    earthquake on March 17, The Tribune’s staff works most of
                    the year from their homes, staying connected mostly by Zoom,
                    Slack and the telephone. At year’s end, The Tribune and the
                    Deseret News end their joint operating agreement, by which
                    they had shared advertising and circulation departments for
                    68 years.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2021</h3>
                  <p>
                    The Tribune ends daily print publication, shifting to
                    emphasis on daily coverage online with a Sunday print
                    edition. With the joint operating agreement ended, The
                    Tribune establishes its own advertising and circulation
                    divisions, becoming what one executive calls “a 150-year-old
                    startup.” The Tribune collaborates with PBS’s documentary
                    series “Frontline” for “Shots Fired,” an investigation on
                    the use of deadly force by Utah law enforcement. The Mormon
                    Land podcast becomes available to paid subscribers through
                    Patreon.
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title">2022</h3>
                  <p>
                    The Tribune adds a midweek print edition, delivered to
                    subscribers by mail on Wednesdays. The news organization
                    experiments with storytelling forms, including a daily
                    podcast and hiring a full-time videographer. The Tribune
                    also sees the results of collaborations with other news
                    entities: A podcast about the “troubled teen” industry,
                    “Sent Away,” with KUER and American Public Media; and the
                    Great Salt Lake Collaborative, uniting 19 news organizations
                    to cover the environmental peril and potential solutions for
                    Utah’s salty landmark.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p>
              <strong>For further reading:</strong>
            </p>
            <p>
              •{" "}
              <a
                href="https://www.nytimes.com/2019/05/17/business/media/paul-huntsman-salt-lake-tribune.html"
                target="_blank"
                rel="noreferrer"
              >
                “Can Paul Huntsman Save The Salt Lake Tribune?”
              </a>
              , Katherine Rosman, The New York Times, May 17, 2019.
            </p>
            <p>
              •{" "}
              <a
                href="https://www.sltrib.com/news/2019/11/04/historic-shift-salt-lake/"
                target="_blank"
                rel="noreferrer"
              >
                “In historic shift, The Salt Lake Tribune gets IRS approval to
                become a nonprofit”
              </a>{" "}
              , Matt Canham, The Salt Lake Tribune, Nov. 4, 2019.
            </p>
          </div>
          <div className="next-section">
            <h4>
              Next Section: <Link to="/nonprofit-basics">Nonprofit Basics</Link>
            </h4>
          </div>
          <hr />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Introduction;
