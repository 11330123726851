import React from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "./Collaborations.scss";
import TagManager from "react-gtm-module";

const Collaborations = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      pagePath: '/collaborations',
      pageTitle: 'Collaborations',
    },
  });
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Collaborations",
    description:
      "Check out some of the other news outlets the Salt Lake Tribune has teamed up with in order to spread local news.",
    canonical: "https://playbook.sltrib.com/collaborations",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "nonprofit, nonprofit playbook, the salt lake tribune, nonprofit newsroom, nonprofit news, news collaborations, amplify utah, great salt lake collaboartive, shots fired, utah news collaborative",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="collaborations">
        <div className="collaborations-content">
          <h1>Collaborations</h1>
          <p>
            Something we learned after becoming a nonprofit: We have a lot of
            friends in the journalism world, and they are eager to team up. We
            are no longer competing with each other. We are competing with other
            demands on people’s time and attention.
          </p>
          <p>
            Collaborations have become a critical tool in helping The Tribune
            and others strengthen and improve access to local news and
            information in Utah. They have helped us tackle big stories that we
            could not have been able to cover on our own, and they have allowed
            us to share our reporting in communities where we otherwise wouldn’t
            have reach or impact.
          </p>
          <p>Here are some of our collaborations:</p>
          <h2>
            <strong>Utah News Collaborative</strong>
          </h2>
          <p>
            After a statewide listening effort, The Tribune and partner news
            organizations launched the Utah News Collaborative in March 2021, as
            a way to share local reporting. The loose affiliation of TV, radio
            and print news organizations communicates via email and Slack,
            alerting partners to daily reporting or journalism that may be
            relevant to Utah communities. It filled two gaps multiple news
            organizations told us they experienced: 1) They weren’t getting
            daily reporting from the Legislature farther afield, and 2) Those in
            Salt Lake City had little awareness of what was happening in rural
            communities.
          </p>
          <h2>
            <strong>“Shots Fired”</strong>
          </h2>
          <p>
            The Tribune and PBS’s “Frontline” worked together to co-publish an
            award-winning documentary and series of stories, called ”Shots
            Fired.” The project compiled a database of police shootings in Utah,
            something no government agency — federal, state or municipal — had
            ever done. The documentary appeared on PBS stations in November
            2021, and we hosted several viewings, including one at a local movie
            theater.
          </p>
          <img
            src="/shots-fired.jpg"
            className="responsive center"
            alt="Shots Fired"
            loading="lazy"
          ></img>
          <h2>
            <strong>“Sent Away”</strong>
          </h2>
          <p>
            ”Sent Away” is a seven-part podcast on the “troubled teen” industry
            in Utah. Tribune reporter Jessica Miller had reported for years on
            troubled teens in Utah and the treatment centers that had sprung up
            offering services to desperate parents. Her deep knowledge was
            shared on a new platform with many more listeners than if we had
            produced it alone. American Public Media and local NPR station KUER
            led the production, with The Tribune’s Miller sharing original
            reporting and her vast expertise. Six months after publication, the
            podcast had reached more than 1.5 million people.
          </p>
          <img
            src="/sent-away.jpg"
            className="responsive center"
            alt="Sent Away"
            loading="lazy"
          ></img>
          <h2>
            <strong>Great Salt Lake Collaborative</strong>
          </h2>
          <p>
            In 2022, The Tribune co-founded the Great Salt Lake Collaborative.
            With support from Solutions Journalism Network, in one year 23
            organizations have published more than 200 stories and hosted more
            than a dozen events, including tours of the lake.
          </p>
          <p>
            We’ve shared stories on how homeowners and renters can “
            <a
              href="https://www.sltrib.com/news/environment/2021/08/11/thinking-going-water-wise/"
              target="_blank"
              rel="noreferrer"
            >
              flip their strip
            </a>
            ” and on how farmers can{" "}
            <a
              href="https://www.sltrib.com/news/environment/2022/03/17/how-change-utah-water-law/"
              target="_blank"
              rel="noreferrer"
            >
              let their water rights
            </a>{" "}
            go into the Great Salt Lake - thanks to a new law - without losing
            them altogether.
          </p>
          <p>
            The governor and elected officials{" "}
            <a
              href="https://www.sltrib.com/news/environment/2022/10/14/water-districts-funnel-billions/"
              target="_blank"
              rel="noreferrer"
            >
              have called summits
            </a>{" "}
            and hosted lake flyovers.{" "}
            <a
              href="https://www.nytimes.com/2022/06/07/climate/salt-lake-city-climate-disaster.html"
              target="_blank"
              rel="noreferrer"
            >
              The New York Times
            </a>{" "}
            and{" "}
            <a
              href="https://www.washingtonpost.com/weather/2021/07/27/great-salt-lake-lake-powell-historic-low-water-levels/"
              target="_blank"
              rel="noreferrer"
            >
              The Washington Post
            </a>{" "}
            have followed up our reporting. In its 2022 session, the Utah
            Legislature{" "}
            <a
              href="https://www.sltrib.com/news/environment/2022/02/19/im-not-sure-lake-will/"
              target="_blank"
              rel="noreferrer"
            >
              allocated $40 million
            </a>{" "}
            to saving the lake. Just before 2022 ended, President Joe Biden
            signed a bill — backed by members of Utah’s congressional
            delegation, Sen. Mitt Romney and Rep. Blake Moore —{" "}
            <a
              href="https://www.sltrib.com/news/environment/2022/12/28/biden-signs-bill-study-salt/"
              target="_blank"
              rel="noreferrer"
            >
              approving $25 million
            </a>{" "}
            in federal money to study the lake’s problems.
          </p>
          <p>
            One water reporter, who has been covering the lake for a decade,
            said, “This is the most engaged I’ve seen members of the public on
            the matter. I don't know if it's the collaborative, lawmakers
            speaking up about it or something else, but it's nice to see Utahns
            and the rest of the nation paying attention.”
          </p>
          <img
            src="/utah-collaborative.jpeg"
            className="responsive center"
            alt="Utah News Collaborative"
            loading="lazy"
          ></img>
          <h2>
            <strong>Amplify Utah</strong>
          </h2>
          <p>
            To give the next generation of journalists a taste of what we do,
            The Tribune collaborates with college journalism departments on a
            program called{" "}
            <a href="https://amplifyutah.org/" target="_blank" rel="noreferrer">
              Amplify Utah
            </a>
            A faculty adviser at Salt Lake Community College sends us articles
            written by her student journalists, which The Tribune then edits to
            our standards and publishes — expanding our coverage to areas we may
            not have bandwidth for, and giving those students exposure in the
            state’s largest newspaper. After the first couple of years working
            with SLCC, the program is slowly expanding to Utah State University
            and Utah Valley University.
          </p>
          <div className="next-section">
            <h4>
              Next Section: <Link to="/final-thoughts">Final Thoughts</Link>
            </h4>
          </div>
          <hr />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Collaborations;
