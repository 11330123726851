import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./Navbar.scss";

const Navbar = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };
  return (
    <nav className="navbar">
      <Link to="/">
        <img
          src="/tribunet.svg"
          alt="Salt LakeTribune Logo"
          className="nav-logo"
        />
      </Link>
      <div onClick={handleClick} className="nav-icon">
        {open ? <FiX /> : <FiMenu />}
      </div>
      <ul className={open ? "nav-links active" : "nav-links"}>
        <li className="nav-item">
          <Link to="/" className="nav-link" onClick={closeMenu}>
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/introduction" className="nav-link" onClick={closeMenu}>
            Introduction
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/nonprofit-basics" className="nav-link" onClick={closeMenu}>
            Nonprofit Basics
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/will-it-work-for-you"
            className="nav-link"
            onClick={closeMenu}
          >
            Will it Work for You?
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/making-the-transition"
            className="nav-link"
            onClick={closeMenu}
          >
            Transitioning
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/governance" className="nav-link" onClick={closeMenu}>
            Governance
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/building-support" className="nav-link" onClick={closeMenu}>
            Building Support
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/collaborations" className="nav-link" onClick={closeMenu}>
            Collaborations
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/final-thoughts" className="nav-link" onClick={closeMenu}>
            Final Thoughts
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/resources" className="nav-link" onClick={closeMenu}>
            Resources
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/acknowledgements" className="nav-link" onClick={closeMenu}>
            Acknowledgements
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default Navbar;
