import React from "react";
import { Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import "./BoardDirectors.scss";

const BoardOfDirectors = () => {
  const meta = {
    title: "The Salt Lake Tribune Nonprofit Playbook: Governance",
    description:
      "As a nonprofit news outlet, the Salt Lake Tribune operates for the benefit of the people of Utah. A board of directors is chosen to represent the community. Find out more about our baord of directors.",
    canonical: "https://playbook.sltrib.com/governance",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "nonprofit, nonprofit playbook, the salt lake tribune, nonprofit newsroom, nonprofit news, board",
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="board">
        <div className="board-content">
          <h1>Governance</h1>
          <img
            src="/lauren.jpeg"
            className="responsive center"
            alt="executive-editor-lauren"
            loading="lazy"
          ></img>
          <p>
            Now that you have launched your nonprofit, there’s another big
            question: Who’s in charge?
          </p>
          <p>
            A for-profit news outlet has an owner. Maybe it’s a big corporation
            far away that owns a bunch of news outlets just like yours. Maybe
            it’s a wealthy person or family. The ownership plan is
            straightforward: Return a profit to the owner or the shareholders.
          </p>
          <p>
            As a tax-exempt, public, nonprofit corporation, The Tribune exists
            and operates to benefit the people of Utah. It has no shareholders
            or members. It’s owned by the community that supports it. A board of
            directors is picked to represent the community.
          </p>
          <hr />
          <h2>Growing your board of directors</h2>
          <p>
            The First Board of Directors for The Salt Lake Tribune was formed
            before the IRS approved our nonprofit application. The paper’s
            then-owner, Paul Huntsman, formed a “friends and family” board —
            something that’s typical in founding documents — made up of three
            personal and professional associates.
          </p>
          <p>
            That board served as a starter, a placeholder until the full board
            could be assembled. The board’s chair, Huntsman, invited some
            community members to apply to be on the full board. Many people
            reached out to The Tribune, to suggest themselves or others — and to
            offer their opinion on what qualifications were needed for a board
            member.
          </p>
          <p>
            Among the criteria considered for the first board: Long-standing
            support for The Tribune and its local journalism; success in their
            own field; enthusiasm and ability to be an active and involved board
            member; and a diversity of lived experience — including being able
            to reflect a cross-section of the community, through their political
            or religious affiliation, geography, race, ethnicity or gender.
          </p>
          <p>
            In other words, we wanted a board that looked like the community The
            Tribune serves.
          </p>
          <p>
            Huntsman and, often, The Tribune’s executive editor (at the time,
            Jennifer Napier-Pearce) interviewed candidates. By April 2020, a
            nine-member board was assembled.
          </p>
          <hr />
          <h2>What the board does</h2>
          <p>
            The board collectively, and the directors individually, have
            fiduciary responsibilities with absolute and comprehensive authority
            over the management and operations of The Tribune.
          </p>
          <p>
            The Tribune’s mission includes disseminating robust news coverage
            and commentary on which democracies and informed citizenry thrive.
            To that end, the board is responsible for ensuring there are
            sustainable revenues with which The Tribune can produce essential
            news, thoughtful commentary, operational efficiency and sustained
            growth.
          </p>
          <p>
            The board doesn’t run The Tribune on a day-to-day basis. Following
            sound administrative practice, it delegates to the executive editor
            and senior staff members. The organization’s leadership has the task
            of daily oversight of news content gathering and presentation, and
            digital and revenue operations.
          </p>
          <p>However, this delegation is not absolute.</p>
          <p>
            The board has the right, authority, and — in some situations — the
            responsibility to intervene in matters related to editorial content,
            newsgathering, writing, revenues, and the distribution of the
            publication. The board may have the responsibility to directly
            intervene if circumstances arise that, in the board’s determination,
            would cause serious and immediate harm to The Tribune.
          </p>
          <p>Nevertheless, the board strives to avoid:</p>
          <ul>
            <li>Prior restraint of news content.</li>
            <li>
              Specific intervention with staff (other than the executive editor
              and senior staff members). However, the board may have informal
              conversations with Tribune personnel, giving their opinions and
              advice — but not orders.
            </li>
            <li>
              Direct financial, political, business, personal, organizational
              and religious conflicts of interest.
            </li>
          </ul>
          <p>
            The board, particularly in the early going, takes time to gel, and
            understand how the nonprofit works. Eventually board members will
            reach the end of their 3-year terms, and will be replaced by new
            members, chosen by a governance committee, or will be nominated for
            another term (no board member can serve more than 3 consecutive
            3-year terms). These changes ensure that the board regularly
            welcomes fresh perspectives, adds to the community engagement, and
            allows for turnover at the leadership level.
          </p>
          <p>
            One more thought: The board you have for your first year may not be
            the board you need by the third or fourth year. When launching the
            nonprofit, you may need board members with expertise in, for
            example, community outreach or digital strategies — things your
            outlet is just learning to do. By year three or four, you may find
            the most important criterion for a board member is the ability to
            donate money and to convince their friends to donate, too. Be
            deliberate about how build your path to board growth.
          </p>
          <p>
            The most important thing: The board represents the community. It’s
            up to them to listen to the community, and bring what they hear back
            to the rest of the board. It’s up to the executive editor, and the
            staff, to listen to the board — and also listen to the community
            when it expresses itself through other channels.
          </p>
          <hr />
          <h2>Vetting prospective board members</h2>
          <p>
            Every nonprofit needs a strong, supportive board. The board’s
            purpose should be clearly articulated in the organization’s bylaws —
            particularly in defining the boundaries between the board’s
            governance prerogatives and the management prerogatives of the
            officers (in this case, the editors). Writing a job description for
            board members isn’t a requirement, but it’s good practice.
          </p>
          <p>
            It’s expected that board members support the organization
            financially — both with personal contributions, and through their
            connections in the community. It helps to have several high-profile
            community leaders on the board who can make generous gifts to the
            organization, but it’s also good for board members to have other
            skills ets, such as nonprofit management or governance experience,
            and financial, technical and legal expertise. Diversity is very
            important, whether it’s demographically, geographically or in points
            of view.
          </p>
          <p>
            Finding and recruiting board members requires great care. Be clear
            up front about what the expectations are, both in time and
            philanthropic support. When a community leader is lobbying to join
            the board, be particularly careful, because they may have ulterior
            motives.
          </p>
          <p>
            Another reason it’s important to choose good people on your board is
            it’s hard to get rid of bad ones. The process of removing
            ineffective board members is usually difficult enough that most
            boards avoid it — and at The Tribune, board members typically serve
            from 3 to 6 years. So it’s far better to get it right at the
            beginning, during the recruiting process.
          </p>
          <p>
            <strong>Questions you might ask prospective board members</strong>
          </p>
          <ol>
            <li>
              What is the mission of the organization? Or, what do you see as
              the role of this news organization?
            </li>
            <li>
              How do you think you can help our organization as a board member?
            </li>
            <li>
              As a news consumer, what do you appreciate about this
              organization? And where do you think it can be better?
            </li>
            <li>Why do you want to volunteer for this board?</li>
            <li>
              How do you see the difference between governance and management?
            </li>
            <li>
              Are you willing to regularly attend board meetings and make a
              financial contribution to our organization? i.e.: Are you willing
              to invest your time and treasure?
            </li>
            <li>
              Are you willing to accept a committee assignment on the board?
            </li>
            <li>
              Can you share any potential conflicts of interest? If there is a
              conflict of interest in the future, or the appearance of conflict,
              are you willing to step aside on that issue?
            </li>
          </ol>
          <p>
            <strong>
              Questions board members might ask of your organization
            </strong>
          </p>
          <ol>
            <li>
              What is the mission of your organization? Or, what do you see as
              the role of this news organization?
            </li>
            <li>
              Before I agree to serve on this board, can you share the budgets
              and P&L for the past 3-5 years?
            </li>
            <li>
              What are your expectations of board members, both in time
              commitment and financial support?
            </li>
            <li>What are the responsibilities of board members?</li>
            <li>Do you have a job description for board members?</li>
            <li>
              What are your primary goals and concerns for the next 3-5 years?
            </li>
            <li>
              Who is currently on the board or committed to it? (Some of this
              information should be available online.)
            </li>
            <li>Where do you need help?</li>
            <li>What is the role of a journalist?</li>
            <li>
              What level of say do you believe donors ought to have in news
              coverage?
            </li>
          </ol>
          <hr></hr>
          <h2>The editorial board</h2>
          <p>The Tribune also has an editorial board.</p>
          <p>
            The editorial board publishes regular editorials, providing The
            Tribune’s opinion on important issues of the day, as it has done
            since 1871. The board still meets with candidates for public office
            — and we try to share their positions, often publishing their own
            words.
          </p>
          <p>
            There is one major difference in how the editorial board operates
            now: We do not endorse candidates, from county clerk to President of
            the United States. We can’t, according to the law governing any
            501(c)(3),{" "}
            <a
              href="https://www.irs.gov/charities-non-profits/charitable-organizations/the-restriction-of-political-campaign-intervention-by-section-501c3-tax-exempt-organizations"
              target="_blank"
              rel="noreferrer"
            >
              per the IRS
            </a>
            :
          </p>
          <p>
            <em>
              “Contributions to political campaign funds or public statements of
              position (verbal or written) made on behalf of the organization in
              favor of or in opposition to any candidate for public office
              clearly violate the prohibition against political campaign
              activity. Violating this prohibition may result in denial or
              revocation of tax-exempt status and the imposition of certain
              excise taxes.”
            </em>
          </p>
          <p>
            Organizations that choose to seek 501(c)(4) status{" "}
            <a
              href="https://www.irs.gov/charities-non-profits/eo-operational-requirements-endorsing-candidates-for-public-office"
              target="_blank"
              rel="noreferrer"
            >
              may endorse candidates for public office
            </a>
            , provided that is not the organization’s primary objective. There
            are, however, other limitations on a 501(c)(4), not related to
            endorsements but to philanthropic giving.
          </p>
          <p>
            We believe that when they have trusted and reliable information on
            local candidates, Utah voters are the best positioned to make
            decisions that align with their values. Because of that, losing the
            ability to make an endorsement was not as significant as those
            outside of our organization thought it would be.
          </p>
          <br></br>
          <br></br>
          <br></br>
          <div>
            <p>
              <strong>For further reading:</strong>
            </p>
            <p>
              •{" "}
              <a
                href="https://www.wildapricot.com/blog/nonprofit-board"
                target="_blank"
                rel="noreferrer"
              >
                “The Complete Guide to Building a Nonprofit Board”
              </a>
              , Tatania Morand, WildApricot.com, March 9, 2021.
            </p>
            <p>
              •{" "}
              <a
                href="https://nonprofitquarterly.org/building-an-effective-board-of-directors/"
                target="_blank"
                rel="noreferrer"
              >
                “Building an Effective Board of Directors”
              </a>{" "}
              , Stephanie Roth, Nonprofit Quarterly, April 11, 2020.
            </p>
          </div>
          <div className="next-section">
            <h4>
              Next Section: <Link to="/building-support">Building Support</Link>
            </h4>
          </div>
          <hr />
        </div>
      </div>
    </DocumentMeta>
  );
};

export default BoardOfDirectors;
